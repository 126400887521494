import React, { useEffect, useState, useRef } from 'react'
import { Link, useOutletContext, useParams } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import { useAuth } from '../../Context/AuthContext'
import { UseInbox } from '../../Context/InboxContext'
import '../CommonChat.css'
import EmojiPicker from 'emoji-picker-react';
//Icons
import send from '../../Assets/Icons/send.png'
import hearts from '../../Assets/dash-hearts.png'
import emoji from '../../Assets/Icons/emoji.png'

// artist side chat, i.e. for messages from artist to client
export default function Chat() {

    let { role } = useAuth()
    let { id } = useParams()
    let { getUnreadChats } = UseInbox()

    const [messages, setMessages] = useState([])
    const [message, setMessage] = useState("")
    const [chat, setChat] = useState("")
    const [emojisOpen, setEmojisOpen] = useState(false)
    const [quoteUrl, setQuoteUrl] = useState("")

    const messagesEndRef = useRef(null)

    const { getChats } = useOutletContext()

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        // Effect to update quoteUrl based on messages
        messages.forEach((msg) => {
            if (msg.message) {
                const quoteRegex = /Quote ([a-fA-F0-9]{24})/;
                const match = msg.message.match(quoteRegex);
                if (match) {
                    const requestURL = `/artists/requests/${chat.requestId}`;
                    setQuoteUrl(requestURL);
                }
            }
        });
    }, [messages, chat]);


    useEffect(() => {
        scrollToBottom()
    }, [messages]);

    useEffect(() => {
        getChat()
        getMessages()
    },[id])

    const getChat = () => {
        axios.post(`${process.env.REACT_APP_API}/chats/get-artist-chat-by-id`, {chatId: id})
        .then((res) => {
            setChat(res.data)
            getChats()
        })
        .catch((error) => console.log(error))
    }

    const getMessages = () => {
        axios.post(`${process.env.REACT_APP_API}/chats/get-messages-by-chat-id`, {chatId: id})
        .then((res) => {
            setMessages(res.data)
            getUnreadChats()
        })
        .catch((error) => console.log(error))
    }

    const sendMessageToClient = () => {
        if (!message || !role) {
            return
        } else{
            axios.post(`${process.env.REACT_APP_API}/chats/send-artist-message`, {
                chatId: id,
                clientEmail: chat.clientEmail,
                message,
                sentBy: role.charAt(0).toUpperCase() + role.slice(1)
            })
            .then((res) => {
                setMessage("")
                getMessages()
                getChats()            })
            .catch((error) => console.log("Error sending message to client", error))
        }
    }

    const sendMessageToSupport = () => {
        if (!message || !role) {
            return
        } else{
            axios.post(`${process.env.REACT_APP_API}/chats/send-message-to-adminsupport`, {
                chatId: id,
                message,
                senderName: chat.artistName,
                senderEmail: chat.artistEmail,
                sentBy: role.charAt(0).toUpperCase() + role.slice(1)
            })
            .then((res) => {
                setMessage("")
                getMessages()
                console.log(res.data)
            })
            .catch((error) => console.log("Error sending message to admin support", error))
        }
    }


    const renderMessage = (msg, i) => {
        const lines = String(msg.message).split("\n")

        return (
            <div key={`msg-${i}`}>
                {lines.map((line, index) => {
                    const quoteRegex = /Quote ([a-fA-F0-9]{24})/
                    const actRegex = /For proposed act (.*?): (.*)/
                    const quoteMatch = line.match(quoteRegex)
                    const actMatch = line.match(actRegex)
                    if (quoteMatch) {
                        const requestURL = `/artists/requests/${chat.requestId}`
                        return (
                            <p key={`quote-${i}-${index}`}>
                                <a
                                    style={{fontFamily: 'Mont', color:'grey'}}
                                    href={requestURL} target="_blank" rel="noopener noreferrer"
                                >
                                    View or amend the quote
                                </a>{" "}
                                you provided to the client
                            </p>
                        )
                    } else if (actMatch) {
                        const prefix = line.split(actMatch[1])[0]
                        const actURL = `/find/artists/${actMatch[2]}`
                        return (
                            <p key={`act-${i}-${index}`}>
                                <span>{prefix}</span>
                                <a
                                    style={{fontFamily: 'Mont', color:'grey'}}
                                    href={actURL} target="_blank" rel="noopener noreferrer"
                                >
                                    {actMatch[1]}
                                </a>
                            </p>
                        )
                    } else {
                        return (
                            <React.Fragment key={`line-${i}-${index}`}>
                                {line} <br />
                            </React.Fragment>
                        )
                    }})
                }
            </div>
        )
    }

    const handleEmoji = (emoji) => {
        setMessage(`${message}${emoji.emoji}`)
        setEmojisOpen(false)
    }


    return (
        <div className='client-chat'>

            {!chat.ticketId && <div id='client-chat-fab'>
                <p style={{color: '#a9a9a9', backgroundColor: 'white', margin: 0, marginTop: 10}}>Need to make a change?</p>
                <Link to={quoteUrl}>
                    <button className='primary-btn'>Amend Quote</button>
                </Link>
            </div>}

            {messages.map((msg, i) => {
                return <div key={`container-${i}`} className={msg.sentBy === "Artist" ? 'client-msg-container' : undefined}>
                    <div key={`msg-${i}`} className={msg.sentBy === "Artist" ? 'client-msg-user' : 'client-msg-other'}>

                        {renderMessage(msg, i)}

                        <p className='client-timestamp'>{moment(msg.timeStamp).format('HH:mm A, DD MMM')}
                            {chat.ticketId && (
                                <>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <em title={`${chat.ticketId}`}>
                                        Support reference: {chat.ticketId.slice(-10)}
                                    </em>
                                </>
                            )}
                        </p>
                    </div>

                    {(i === messages.length - 2) && (
                        <div ref={messagesEndRef}> </div>
                    )}
                </div>

            })}

            <img id='chat-hearts' src={hearts} alt='First Dance Music Wedding Hearts'/>

            <div id='client-message-container'>
                <div id='client-msg-box'>
                    <img id='client-emoji-selector' className='hover' src={emoji} alt='First Dance Music Wedding Emoji' onClick={() => setEmojisOpen(!emojisOpen)}/>
                    <textarea
                        id='client-chat-textarea'
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onFocus={() => setEmojisOpen(false)}
                        placeholder='Your message'
                    />

                    <img id='client-send-icon' className='hover' src={send} onClick={chat.ticketId ? sendMessageToSupport : sendMessageToClient}/>
                </div>
                <div>                    
                    <EmojiPicker 
                        open={emojisOpen}
                        style={{position: 'fixed', bottom: 150, zIndex: 1000}}
                        onEmojiClick={(emoji) => handleEmoji(emoji)}
                        
                    />
                </div>

            </div>
        </div>
    )
}
