import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import moment from 'moment'
import { useAuth } from '../../Context/AuthContext';
import { Link } from 'react-router-dom';
import './ArtistDashboard.css'
import DashboardCalendar from './DashboardCalendar';
import { UseInbox } from '../../Context/InboxContext';
// Icons
import letter from '../../Assets/Icons/letter.png'
import quote from '../../Assets/Icons/dash-quote.png'
import discoBall from '../../Assets/Icons/disco-ball.png'
import hearts from '../../Assets/dash-hearts.png'
import calendar from '../../Assets/Icons/dash-calendar.png'
import { ActTypes } from '../../Constants';

export default function ArtistDashboard() {

    let { currentUser } = useAuth()
    let { unreadChats } = UseInbox()

    let clientName = currentUser.displayName;
    const [requests, setRequests] = useState([])
    const [bookings, setBookings] = useState([])
    const [nextGig, setNextGig] = useState(null)
    const [calendarOpen, setCalendarOpen] = useState(false);

    useEffect(() => {
        getRequests()
        getBookings()
    }, [])


    const getRequests = () => {
        axios.post(`${process.env.REACT_APP_API}/requests/get-all-requests-filtered`, {
            artistId: currentUser.uid
        })
        .then((res) => {
            //This eliminates any quotes that are onPause but that an artist hasn't aready engaged with
            let filtered = res.data.filter((request) => {  return !(deriveRequestStatus(request) === "Not Quoted" && request.onPause === true);})
            let sorted = filtered.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            setRequests(sorted)
        })
        .catch((e) => console.log(e))
    }

    const deriveRequestStatus = (request) => {
        if ((request.fulfilled === true) && (request.artistId === currentUser.uid)) {
            return 'Booked'
        } else if (request.quoted.includes(currentUser.uid)) {
            return 'Quoted'
        }  else {
            return 'Not Quoted'
        }
    }

    const getBookings = () => {
        axios.post(`${process.env.REACT_APP_API}/bookings/get-bookings-by-id`, {id: currentUser.uid})
        .then((res)=>{
            setBookings(res.data)
            if(res.data.length > 0){
                const today = moment();
                // Step 1: Filter dates that are today or in the future
                const futureEvents = res.data.filter(event => moment(event.date).isSameOrAfter(today, 'day'));
                // Step 2: Sort the events by date
                futureEvents.sort((a, b) => moment(a.date).diff(moment(b.date)));
                // Step 3: Get the next closest event (including today)
                const nextEvent = futureEvents[0];
                setNextGig(nextEvent)
            }

        })
        .catch((error) => {
            console.log(error)
        })
    }

    return (
        <div id='wedding-dash'>
            <div id='wedding-dash-left'>

                <div id='calendar-tab' onClick={() => setCalendarOpen(true)}>
                    <img style={{height: 20, width: 20, objectFit:'contain', filter: 'invert(1)', marginRight: 10}} src={calendar} alt='First Dance Music Wedding Calendar'/>
                    <p style={{margin:0}}>Calendar</p>
                </div>

                <h1 style={{fontWeight: 900, color: '#313131', margin: 0, marginBottom: 10}}>Dashboard</h1>
                <p style={{ margin: 0, marginBottom: 20}}>Manage all your wedding bookings in one place</p>

                <div id='countdown-container'>
                    <h2 style={{fontSize: 32, margin: 0}}>Hello {clientName?.split(" ")[0]},</h2>
                    <div className='countdown-info'>
                        {nextGig ?
                        <div className='countdown-info'>
                            <p id='days-to-go'>{moment(nextGig.date).diff(moment(), 'days') + 1}</p>
                            <p id='countdown-txt'>days to your next gig!</p>
                            <p id='countdown-txt-mobile'>days to next gig!</p>
                        </div>
                        :
                        <div className='countdown-info'>
                            <p id='days-to-go'>0</p>
                            <p id='countdown-txt'>upcoming gigs</p>
                            <p id='countdown-txt-mobile'>upcoming gigs</p>
                        </div>
                        }


                        <img id='dash-calendar-icon' src={calendar} alt='First Dance Music Wedding Icon'/>

                    </div>

                </div>
                <div id='wedding-dash-containers'>

                    <Link to={'weddings'} className='wedding-dash-container'>
                        <img className='wedding-dash-img' src={quote} alt='First Dance Music Wedding Icon'/>
                        <p style={{marginRight: 7}}>{requests.length} Active</p>
                        <p>Requests</p>
                    </Link>

                    <div className='wedding-dash-container'>
                        <img className='wedding-dash-img' src={discoBall} alt='First Dance Music Wedding Icon'/>
                        <p style={{marginRight: 7}}>{bookings.length} Confirmed</p>
                        <p>{bookings.length === 1  ? "Booking" : "Bookings"}</p>
                    </div>

                    <Link to={'inbox'} className='wedding-dash-container'>
                        <img className='wedding-dash-img' src={letter} alt='First Dance Music Wedding Icon'/>
                        <p style={{marginRight: 7}}>{unreadChats.length} New</p>
                        <p>Messages</p>
                    </Link>
                </div>

                <div>
                    <h3 style={{fontFamily:"Mont"}}>Latest Requests</h3>

                    {requests.slice(0, 10).map((request, i) => {

                        let icon = ActTypes.find((act) => act.name == request.info?.actType )

                        return (
                            <Link to={`requests/${request._id}`} key={i} style={{textDecoration: 'none', color: '#313131', margin: 0}}>
                                <div className='quote-dash-container'>
                                    <div className='quote-highlight'/>
                                    <div className='quote-top-line'>
                                        <p>{moment(request.date).format('DD/MM/YY')} - {request.name}</p>
                                        <img style={{height: 30, width: 30, objectFit:'contain'}} src={icon?.icon || discoBall} alt='First Dance Music Wedding Icon'/>
                                    </div>
                                    <p
                                        style={{margin: '5px 0', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '95%'}}
                                    >
                                        {request.notes ? request.notes : "No notes added"}
                                    </p>
                                    <p>{request.info.startTime} - {request.info.endTime}</p>
                                </div>

                            </Link>
                        )
                    })}
                    <Link to={'weddings'} style={{fontWeight: 700, color: '#ecbdba'}}>
                    View All
                    </Link>
                </div>

               <img id='dash-hearts' src={hearts}/>
            </div>

            <DashboardCalendar
                calendarOpen={calendarOpen}
                setCalendarOpen={setCalendarOpen}
                requests={requests}
                bookings={bookings}
            />

        </div>
    )
}
