import React, { useState, useEffect} from 'react'
import axios from 'axios'
import moment from 'moment'
import './SystemReport.css'

export default function SystemReport() {

    const [systemInfo, setSystemInfo] = useState("")

    useEffect(() => {
        getSystemInfo()
    }, [])


    const getSystemInfo = () => {
        axios.post(`${process.env.REACT_APP_API}/system/get-system-info`)
        .then((res) => {
            setSystemInfo(res.data)
        })
        .catch((error) => console.log(error))
    }

    return (
        <div id='system-report-page'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <h2 style={{ flex: '1', marginRight: '10px' }}>System Report - Events and Errors</h2>
            </div>
            <table id='system-report-table'>
                <thead>
                    <tr id='thead-tr'>
                        <th>Date</th>
                        <th>Event Type</th>
                        <th>Summmary</th>
                        <th>Details</th>
                    </tr>
                </thead>
                <tbody>
                    {systemInfo.length < 1 ? (
                        <tr>
                            <td colSpan={6} style={{textAlign:'center'}}>
                                No events or errors have been recorded
                            </td>
                        </tr>
                    ) : (
                        systemInfo
                            .sort((a, b) => new Date(b.occurredAt) - new Date(a.occurredAt))
                            .map((event, i) => {
                                return (
                                    <tr key={i}>
                                        <td style={{ whiteSpace: 'nowrap' }}>{moment(event.occurredAt).format('DD/MM/YY HH:mm:ss')}</td>
                                        <td>{event.eventType}</td>
                                        <td>{event.eventSummary}</td>
                                        <td>{event.eventDetails}</td>
                                    </tr>
                                );
                            })
                    )}
                </tbody>
            </table>
        </div>
    )

}