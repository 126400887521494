import React, { useState, useEffect} from 'react'
import { useAuth } from '../../Context/AuthContext'
import axios from 'axios'
import ActContainer from './ActContainer'
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import './Acts.css'
import { toast } from 'react-toastify'
import { Locations, ActTypes } from '../../Constants';

export default function Profile() {

  let { currentUser } = useAuth()
  const [acts, setActs] = useState([])
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // Modal state
  const [actName, setActName] = useState("");
  const [category, setCategory] = useState("");
  const [areas, setAreas] = useState([])

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setActName("");
    setCategory("");
    setAreas([])
  };

  const handleArea = (area) =>{
    console.log(area.checked)
    if(area.checked){
      //Add to array
      setAreas(prev=> [...prev, area.value])
    } else  {
      //remove from array
      let newAreas = areas.filter((arrayItem) => arrayItem !== area.value)
      setAreas(newAreas)
    }
  }

  useEffect(() => {
    getActs()
  }, [])

  const getActs = () => {
    axios.post(`${process.env.REACT_APP_API}/acts/get-acts-by-id`, {artistId: currentUser.uid})
    .then((res)=>{
      setActs(res.data)
      console.log("Acts", res.data)
    })
    .catch((e)=>console.log(e))
  }

  const saveAct = () => {
    let payload = {
      artistId: currentUser.uid,
      primaryImg: "https://firebasestorage.googleapis.com/v0/b/first-dance-music.appspot.com/o/artist_default.png?alt=media&token=10b79004-41a3-48a5-93ce-369685535540",
      images: [],
      video: "",
      actName,
      category,
      subCategory: "",
      areas,
      setList:[],
      published:false,
      featured: false
    }

    if (!actName) {
      toast.error("Please add an act name")
    } else {
      setLoading(true)
    axios.post(`${process.env.REACT_APP_API}/acts/create-new-act`, payload)
    .then((res) => {
      getActs()
      handleClose()
      setLoading(false)
    })
    .catch((e) => {
      console.log(e);
      setLoading(false)
    })
    }
  }


  return (
    <div className='business-outlet' id='act-page'>
      <Fab sx={{backgroundColor: '#ecbdba',  flexShrink: 0}} aria-label="add" onClick={handleOpen}>
        <AddIcon sx={{color: '#fff'}}/>
      </Fab>
      <h2>Acts</h2>
      <p>Create an act for each seperate service you offer eg. Solo / Duo / Band / DJ / Sax etc.</p>

      <div style={{display:'flex', width:'100%', padding:'1rem', flexWrap:'wrap'}}>
        {acts.length< 1 ?
              <h2 colSpan={3} style={{textAlign:'center'}}>No acts created</h2>

            :
            acts.map((act, i)=>{
              return <ActContainer key={i} act={act}/>
            })}
      </div>

    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h2 id="modal-modal-title" >
            New Act
          </h2>
          <p>
            Create all your acts to ensure brides can easily find you
          </p>

          <TextField fullWidth sx={{my:1}} label="Act name" value={actName} onChange={(e)=>setActName(e.target.value)}/>

          <label>Category</label>
          <select value={category} onChange={(e) => setCategory(e.target.value)}>
          <option value="">Select</option>
          {ActTypes.map((actType, i) => (
            <option key={i} value={actType.name}>
              {actType.name}
            </option>
          ))}
          </select>

          <h3>Areas covered</h3>

          {Locations.map((location) => (
            <div key={location}>
              <input
                style={{height: 20, width: 20, border:'1px solid black'}}
                className='location-input'
                type="checkbox"
                id={location}
                name={location}
                value={location}
                onChange={(e) => handleArea(e.target)}
              />
              <label htmlFor={location}>{location}</label>
              <br />
            </div>
          ))}
          <Button variant='contained' sx={{backgroundColor: '#ecbdba', mt:2}} onClick={saveAct} disabled={loading}>{loading? "Saving...":"Create Act"}</Button>
          <Button variant='outlined' sx={{ mt:2, ml:1}} onClick={handleClose} >Cancel</Button>

        </Box>
      </Modal>




    </div>
  )
}


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  maxWidth: '95vw',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  maxHeight: '80vh',
  overflow: 'scroll'
};