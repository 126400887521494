import React, { useState, useEffect} from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import './ClientUI.css'
import {  signOut } from "firebase/auth";
import { auth } from '../../Firebase';
import logo from '../../Assets/logo-stacked-pink.png'
import mobileLogo from '../../Assets/pinkLogo.webp'

export default function MusicianUI() {
  let location = useLocation();

  const [navOpen, setNavOpen] = useState(window.screen.width < 750 ? false : true)
  const [active, setActive] = useState('Dashboard')

  useEffect(() => {
    let url = location.pathname

    if(url === '/clients'){
      setActive("Wedding")
    } else if(url.startsWith('/clients/quote')){
      setActive("Quotes")
    } else if(url.startsWith('/clients/inbox')){
      setActive("Inbox")
    } else {
      setActive("")
    }

  }, [location])

    const handleLogout = () => {
      signOut(auth).then(() => {
        // Sign-out successful.
      }).catch((error) => {
        console.log("Error handling logout", error)
      });
    }



  return (
    <div>

      <div className='mobile-header'>
        <div className={navOpen ? " nav-icon-1 open-burger" : "nav-icon-1"} onClick={() => setNavOpen(!navOpen)}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>

        <Link to={'/'}>
            <img id='mobile-logo' src={mobileLogo}/>
        </Link>
      </div>

        <header className={navOpen ? 'client-nav client-nav-open' : 'client-nav'}>

          <div>

            <Link to={'/'}>
              <img id='sidenav-logo' src={logo} style={{width: '60%', marginLeft: '20%', marginBottom: 30, marginTop: 50}}/>
            </Link>

            <nav >
              <p style={{fontFamily: 'Satoshi', fontSize: 11, color:'#111111', opacity: 0.5}}>MENU</p>
                <Link to={'/clients'} className={active === 'Wedding' ? 'business-nav-item-active' : 'business-nav-item'} onClick={() => window.screen.width < 750 ? setNavOpen(false) : null}>
                  <img style={{width: 35, height: 35, marginBottom: 7}} className={active === 'Wedding' ? 'nav-icon icon-filter' : 'nav-icon'} src={require('../../Assets/Icons/chapel.png')} alt='Church icon'/>
                  <p className={active === 'Wedding' ? 'business-link-txt-active' : 'business-link-txt'}>My Wedding</p>
                </Link>


                <Link to={'quotes'} className={active === 'Quotes' ? 'business-nav-item-active' : 'business-nav-item'} onClick={() => window.screen.width < 750 ? setNavOpen(false) : null}>
                  <img className={active === 'Quotes' ? 'nav-icon icon-filter' : 'nav-icon'} src={require('../../Assets/Icons/dash-quote.png')} alt='Quotes icon'/>
                  <p className={active === 'Quotes' ? 'business-link-txt-active' : 'business-link-txt'}>Quotes</p>
                </Link>
                <Link  to={'inbox'} className={active === 'Inbox' ? 'business-nav-item-active' : 'business-nav-item'} onClick={() => window.screen.width < 750 ? setNavOpen(false) : null}>
                  <img className={active === 'Inbox' ? 'nav-icon icon-filter' : 'nav-icon'} src={require('../../Assets/Icons/letter.png')} alt='Messages icon'/>
                  <p className={active === 'Inbox' ? 'business-link-txt-active' : 'business-link-txt'}>Inbox</p>
                </Link>

            </nav>
            </div>

            <button className='business-nav-item' id='business-logout-btn' onClick={handleLogout}>
                  <img className='nav-icon' src={require('../../Assets/Icons/logout.png')} alt='Logout icon'/>
                  <p className='business-link-txt'>Logout</p>
                </button>

        </header>

        <Outlet context={{ active, setActive }}/>
    </div>
  )
}
