import React, { useState } from 'react'
import RequestList from './RequestList'
import WeddingTabs from './Tabs/WeddingTabs'
import BookingsList from './BookingsList'

export default function ArtistWeddings() {

    const [ tab, setTab ] = useState(1)

  return (
    <div className='business-outlet'>
        <h2 id='wedding-header' style={{ margin:0, fontFamily: 'Mont'}}>Weddings</h2>
        <p>Find and manage wedding bookings all in one place</p>

        <WeddingTabs tab={tab} setTab={setTab}/>

        {tab === 1 && <RequestList />}
        {tab === 2 && <BookingsList />}

    </div>
  )
}
