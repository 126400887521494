import React, { useEffect, useState} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios'
import Avatar from '@mui/material/Avatar';
import moment from 'moment'
import './PublicInbox.css'


export default function PublicInbox() {

    const { userId } = useParams();

    let navigate = useNavigate()
    const [chats, setChats] = useState([])
    const AdminSupportName = "Admin Support"

    useEffect(() => {
        getChats()
    }, [])

    const getChats = () => {
        axios.post(`${process.env.REACT_APP_API}/chats/get-public-user-chats-by-id`, {id: userId})
        .then((res) => {
            setChats(res.data)
        })
        .catch((error) => console.log("Error getting public chats", error))
    }

    return (
        <div id='inbox-page'>
            <h1 style={{textAlign:'center'}}>Support Chats</h1>
            <div id='inbox-container'>
            {chats.length < 1 ? (
                <p style={{ textAlign: 'center' }}>Inbox empty</p>
            ) : (
                chats
                    .slice() // Create a shallow copy of the array to avoid mutating the original
                    .sort(function(a, b) {
                        return new Date(b.lastMsgAdded) - new Date(a.lastMsgAdded);
                    })
                    .map((chat, i) => {
                        return (
                            <div key={i} className='inbox-row' onClick={() => navigate(`/inbox/${userId}/${chat._id}`)}>
                                {/* the only chats present should be with admin */}
                                <div style={{ display: 'flex' }}>
                                    <Avatar sx={{ width: 50, height: 50, mr: 3 }}>{AdminSupportName.charAt(0)}</Avatar>
                                    <p
                                        className='inbox-name'
                                        style={{ fontWeight: chat.clientRead ? 'normal' : 'bold' }}
                                    >
                                        {AdminSupportName}
                                    </p>
                                </div>
                                <p style={{ textAlign: 'center' }}>{chat.lastMsg.slice(0,10)} ...</p>
                                <p style={{ textAlign: 'right' }}>{moment(chat.lastMsgAdded).fromNow()}</p>
                            </div>
                        );
                    })
                )}
            </div>
        </div>
    )
}
