import React from 'react'
import decoration from '../../../Assets/how-it-works.png'
import dj from '../../../Assets/dj.webp'

export default function ArtistHowItWorks({ setOpen }) {
  return (
    <div id='how-it-works'>
    <h2 id='how-it-works-header'>Artist Sign Up</h2>
    <p style={{width: 600, maxWidth: '100%', fontWeight: 600, fontSize: 18, color: '#313131', marginBottom: 100}}>With First Dance Music, managing your bookings is effortless. You can view all your events in your calendar, communicate directly with clients through the integrated messenger tool, and eliminate the need to chase payments.</p>
    <img id='decoration' src={decoration}/>

    <div id='steps'>
      <img id='dj-rectangle' src={dj}/>

      <div style={{display:'flex', flexWrap: 'wrap'}}>
          <div className='how-it-works-step'>
            <h2>01.</h2>
            <h3>Create Acts</h3>
            <p>Create as many acts as you like under one profile. Whether you DJ, play guitar, or sing, you can manage everything in a single account. Upload images, videos, and setlists to showcase your talents effectively.</p>
          </div>
          <div className='how-it-works-step'>
            <h2>02.</h2>
            <h3>Provide Quotes</h3>
            <p>Receive quote requests directly or for any act within your category. Just fill out the quote form and send it along with a friendly message.</p>
          </div>
          <div className='how-it-works-step'>
            <h2>03.</h2>
            <h3>Communicate</h3>
            <p>Communicate directly with clients using the integrated messaging tool, ensuring that both you and your clients have all the necessary information for the big day.</p>
          </div>
          <div className='how-it-works-step'>
            <h2>04.</h2>
            <h3>Confirm Bookings</h3>
            <p>Once the client agrees to proceed, we will begin collecting the funds, which you can withdraw in full after the event.</p>
          </div>

          <div>
            <button className='hero-btn' onClick={() => setOpen(true)}>Sign Up Now</button>
          </div>
      </div>

    </div>
</div>
  )
}
