import React, { useState, useEffect} from 'react'
import axios from 'axios'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import '../RequestList.css'
import { useAuth } from '../../../Context/AuthContext'

export default function AllRequestList() {

    let { currentUser } = useAuth()

    let navigate = useNavigate()

    useEffect(() => {
        getRequests()
    }, [])

    const [requests, setRequests] = useState([])
    const [showHistoric, setShowHistoric] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'ascending' });

    const getRequests = () => {
        axios.post(`${process.env.REACT_APP_API}/requests/get-all-requests-unfiltered` )
        .then((res) => {
            setRequests(res.data)
        })
        .catch((error) => console.log(error))
    }

    const sortedRequests = [...requests].sort((a, b) => {
        if (sortConfig.key === 'createdAt') {
            const dateA = moment(a[sortConfig.key])
            const dateB = moment(b[sortConfig.key])

            if (sortConfig.direction === 'ascending') {
                return dateA.isBefore(dateB) ? -1 : 1
            } else {
                return dateA.isBefore(dateB) ? 1 : -1
            }
        } else if (sortConfig.key === 'date') {
            const dateA = moment(a[sortConfig.key], "YYYY-MM-DD")
            const dateB = moment(b[sortConfig.key], "YYYY-MM-DD")

            const isDateAValid = dateA.isValid()
            const isDateBValid = dateB.isValid()

            // Handle cases where either date is invalid
            if (!isDateAValid && !isDateBValid) return 0
            if (!isDateAValid) return 1
            if (!isDateBValid) return -1

            if (sortConfig.direction === 'ascending') {
                return dateA.isBefore(dateB) ? -1 : 1
            } else {
                return dateA.isBefore(dateB) ? 1 : -1
            }
        }

        return 0
    })

    const isRecent = (createdAt) => {
        const createdDate = moment(createdAt);
        const now = moment()
        return createdDate.isAfter(now.subtract(24, 'hours'))
    }

    const handleSort = (key) => {
        let direction = 'ascending'
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending'
        }
        setSortConfig({ key, direction })
    }

    // TODO consider:
    //   - a client is not interested, the quote is stale
    //   - other artists could still quote, useful to see list of who has/has not
    //   - how many artists have rejected (archived)
    //   - having a quote is optimistic but if client is not interested and
    //     all others rejected then how to move forward
    //   - maybe show Archived state also for quoted
    const deriveRequestStatus = (request) => {
        if (request.fulfilled === true) {
            return 'Booked'
        } else if (request.quoted && request.quoted.length > 0) {
            return 'Quoted'
        } else if (request.archived && request.archived.length > 0) {
            if (!!request.artistId && !request.fulfilled) {
                // an archived direct request is an outright reject
                // as opposed to an open request that some archive/reject
                return 'Rejected'
            } else {
                return 'Not Quoted (A)'
            }
        }  else {
            return 'Not Quoted'
        }
    }

    const handleShowHistoricChange = () => {
        setShowHistoric(!showHistoric);
    };

    return (
        <div id='request-list-page'>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems:'center' }}>
                <h2 style={{ marginRight: '10px', fontFamily: 'Mont' }}>Available wedding requests</h2>

                <label className="switch">
                    <input
                        type="checkbox"
                        checked={showHistoric}
                        onChange={handleShowHistoricChange}
                    />
                    <span className="slider round"></span>
                </label>
                <p style={{marginLeft: 10, fontSize: 12, fontWeight: 'bold'}}>Show Historic</p>

            </div>
            <table id='request-list-table'>
                <thead>
                    <tr id='thead-tr'>
                        <th>Name</th>
                        <th onClick={() => handleSort('date')}>
                            Wedding Date {sortConfig.key === 'date' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                        </th>
                        <th onClick={() => handleSort('createdAt')}>
                            Request Date {sortConfig.key === 'createdAt' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                        </th>
                        <th>Act Type</th>
                        <th>Start time</th>
                        <th>End time</th>
                        <th>Venue</th>
                        <th>Request</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedRequests.length < 1 ? (
                        <tr>
                            <td colSpan={7} style={{textAlign:'center', paddingTop: 50}}>
                                No requests available
                            </td>
                        </tr>
                    ) : (
                        sortedRequests
                            .filter((request) => {
                                const isHistoric = new Date(request.date) < new Date()

                                if (showHistoric) {
                                    return true;
                                }

                                return !isHistoric
                            })
                            .map((request, i) => {
                                // Check if the request is historic
                                const isHistoric = new Date(request.date) < new Date()

                                // apply a CSS class based on the archived status
                                // use also for historic entries

                                let rowClass
                                if (isHistoric) {
                                    rowClass = 'archived-entry'
                                } else if (isRecent(request.createdAt)) {
                                    rowClass = 'highlighted-row'
                                } else {
                                    rowClass = ''
                                }

                                return (
                                    <tr
                                        key={i}
                                        className={rowClass}
                                        onClick={()=>navigate(`/artists/requests/${request._id}`)}
                                    >
                                        <td>{request.name}</td>
                                        <td>{moment(request.date, "YYYY-MM-DD").format('ddd, DD MMM YYYY')}</td>
                                        <td>{moment(request.createdAt).format('ddd, DD MMM YYYY')}</td>
                                        <td>{request.info.actType}</td>
                                        <td>{request.info.startTime}</td>
                                        <td>{request.info.endTime}</td>
                                        <td>{request.info.venue}</td>
                                        <td>{!request.openRequest ? 'Direct' : 'Open'}</td>
                                        <td>{deriveRequestStatus(request)}</td>
                                    </tr>
                                );
                            })
                    )}
                </tbody>
            </table>
        </div>
    )
}
