import React, { useState, useEffect } from 'react'
import './Login.css'
import { useNavigate, Link } from 'react-router-dom'
import { signInWithEmailAndPassword, sendPasswordResetEmail} from "firebase/auth";
import { toast } from 'react-toastify'
import { auth } from '../../Firebase'
import logo from '../../Assets/logo-stacked.png'
import { useAuth } from '../../Context/AuthContext';
import { LogToServer } from '../../Utilities'


export default function Login() {

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)

  const { role } = useAuth()

  let navigate = useNavigate()

  useEffect(() => {
    if (!role) {
      return
    } else {
      if (role === 'client') {
        navigate('/clients')
      } else if (role === 'artist') {
        navigate('/artists')
      }
    }

  }, [role])

  const togglePasswordVisibility = () => {
    setShowPassword(prevState => !prevState)
  }

  const handleLogin = () => {
    if (!email || !password) {
      toast.error("Please input all fields")
    } else {
      setLoading(true)
      signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        // Signed in
        LogToServer(`User ${email} has logged in`)
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        toast.error(errorMessage)
        LogToServer(`Error logging in for user ${email}. Error code ${errorCode}`, errorMessage)
      })
      .finally(() => {
        setLoading(false)
      })
    }
  }

  const resetPassword = () => {
    if (!email) {
      toast.error("Please input email")
    } else {
      sendPasswordResetEmail(auth, email)
      .then(() => {
        toast.success("Password reset email sent!")
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        toast.error(errorMessage)
      });
    }
  }

  return (
    <div id='login-page'>
      <Link to={'/'}><img id='login-logo' src={logo}/></Link>
      <div id='credentials-container'>
        <input
          className='login-input'
          type='email'
          placeholder='Email'
          value={email}
          onChange={(e)=>setEmail(e.target.value)}
        />

        <input
         className='login-input'
         type={showPassword ? 'text' : 'password'}
         placeholder='Password'
         value={password}
         onChange={(e)=>setPassword(e.target.value)}
        />

        <label style={{ paddingBottom: '15px', fontSize: '12px' }}>
          <input
            type="checkbox"
            onChange={togglePasswordVisibility}
            checked={showPassword}
          />
          Show Password
        </label>

        <button id='login-btn' onClick={handleLogin} disabled={loading}>{loading ? "Logging In..." : "Login"}</button>

        <p id='forgotten' onClick={resetPassword}>Forgotten Password?</p>
      </div>
    </div>
  )
}
