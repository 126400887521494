import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useAuth } from '../../Context/AuthContext'
import axios from 'axios'
import { Link } from 'react-router-dom'
import discoBall from '../../Assets/Icons/disco-ball.png'

export default function BookingsList() {

    const [ bookings, setBookings ] = useState([])
    let { currentUser } = useAuth()

    useEffect(() => {
        getBookings()
    }, [])
     

    const getBookings = () => {
        axios.post(`${process.env.REACT_APP_API}/bookings/get-bookings-by-id`, {id: currentUser.uid})
        .then((res)=>{
            
            if(res.data.length > 0){
                const today = moment(); 
                // Step 1: Filter dates that are today or in the future
                const futureEvents = res.data.filter(event => moment(event.date).isSameOrAfter(today, 'day'));
                // Step 2: Sort the events by date
                futureEvents.sort((a, b) => moment(a.date).diff(moment(b.date)));
                setBookings(futureEvents)
            }
            
        })
        .catch((error) => {
            console.log(error)
        })
    }


  return (
    <div>
        {bookings.length < 1 ?
        <p>No Bookings</p>
        :
        bookings.map((event, i) => {

            return (
                <Link to={`/artists/requests/${event?.requestId}`} key={i} style={{textDecoration: 'none', color: '#313131'}}>
                <div className='quote-dash-container' style={{backgroundColor: 'white', margin: '20px auto', width: '90%'}}>
                    <div className='quote-highlight' style={{backgroundColor: '#C1E1C1'}}/>
                    <div className='quote-top-line'>
                        <p>{event.title}</p>
                        <img style={{height: 30, width: 30, objectFit:'contain'}} src={discoBall} alt='First Dance Music Wedding Icon'/>
                    </div>
                    <p>{moment(event.date).format('DD/MM/YYYY')} - {moment(event.date).fromNow()} </p>

                    
                    <h3 style={{margin: 0, color: '#C1E1C1'}}>Booked</h3>
                </div>
                </Link>
            )
        })
        }
    </div>
  )
}
