import React, { useState } from 'react'
import ArtistFAQs from './Artists/ArtistFAQs'
import ArtistHowItWorks from './Artists/ArtistHowItWorks'
import ModalSignUp from './Modals/ModalSignUp'
import ModalLogin from './Modals/ModalLogin'
import ArtistHowItWorksMobile from './Artists/ArtistHowItWorksMobile'

export default function ArtistSignup() {

  const [ open, setOpen ] = useState(false)
  const [ openLogin, setOpenLogin ] = useState(false)

  return (
    <div>
      <ArtistHowItWorks setOpen={setOpen}/>
      <ArtistHowItWorksMobile setOpen={setOpen}/>
      <ArtistFAQs />

      {open && <ModalSignUp setOpen={setOpen} setOpenLogin={setOpenLogin}/>}
      {openLogin && <ModalLogin setOpen={setOpenLogin}/>}
    </div>
  )
}
