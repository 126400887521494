import React, { useEffect, useState } from 'react'
import { useAuth } from '../../Context/AuthContext'
import './Find.css'
import moment from 'moment'
import { toast } from 'react-toastify'
import axios from 'axios'
import { Locations, ActTypes, Timings, WeddingSchedule, UnspecifiedScheduleId } from '../../Constants';
import { SplitName } from '../../Utilities.js'
import { useNavigate } from 'react-router-dom'

export default function Find() {

  let { currentUser } = useAuth()
  let navigate = useNavigate()

  const [actType, setActType ] = useState("")
  const [performanceSlot, setPerformanceSlot ] = useState("")
  const [name, setName ] = useState("")
  const [email, setEmail ] = useState("")
  const [date, setDate ] = useState("")
  const [startTime, setStartTime ] = useState("")
  const [endTime, setEndTime ] = useState("")
  const [location, setLocation ] = useState("")
  const [venue, setVenue ] = useState("")
  const [notes, setNotes ] = useState("")
  const [loading, setLoading ] = useState(false)

  useEffect(() => {
    if (currentUser?.email) {
      setName(currentUser.displayName);
      setEmail(currentUser.email);
    }
  }, [currentUser]);

  // create client account, save the quote, inform artists
  const handleSubmit = () => {

    if(moment(date).isBefore(moment())){
      toast.error("Please add a date in the future")
    } else if (!name || !email || !venue || !location || !actType) { // performanceSlot is optional
      toast.error("Please add all required fields")
    } else if (!date) {
      toast.error("Please ensure date has been correctly completed")
    } else if (!endTime || !startTime) {
      toast.error("Please ensure start and end times are correctly completed")
    } else {
      setLoading(true)

      const { firstName, lastName } = SplitName(name);

      // add meta's data for server
      const fbpCookie = document.cookie.split('; ').find(row => row.startsWith('_fbp='));
      const fbp = fbpCookie ? fbpCookie.split('=')[1] : null;
      const fbcCookie = document.cookie.split('; ').find(row => row.startsWith('_fbc='));
      const fbc = fbcCookie ? fbcCookie.split('=')[1] : null;

      const eventData = {
        event_name: 'TBD',
        user_data: {
          email: 'user@example.com',  // Hashed on server side
          phone: '',        // Hashed on server side
          fbp,  // Pixel ID
          fbc,  // Click ID
        }
      }

      axios.post(`${process.env.REACT_APP_API}/users/create-new-client`, {
        firstName,
        lastName,
        email,
        img:"https://caretestresources.s3.eu-west-2.amazonaws.com/avatar.png",
        date,
        actType,
        location,
        eventData
      })
      .then((userResponse) => {

        // check that date is not more than two years in the future
        if (moment(date).isAfter(moment().add(2, 'years'))) {
          setLoading(false)

          window.alert(
            "Unfortunately, bookings cannot be more than 2 years in the future.\n\n" +
            "Please use the chat button to contact support and discuss your options."
          )

          return
        }

        // check if there is a performance slot allocated and find the corresponding wedding schedule item
        const matchingSlot = WeddingSchedule.find(slot => slot.name === performanceSlot);

        let payload = {
          title: "Request",
          date: moment(date).format("YYYY-MM-DD"),
          name,
          email,
          clientId: userResponse.data.clientId,
          info: {actType, performanceSlot, startTime, endTime, venue, location},
          openRequest: true,
          notes, backgroundColor:'#ecbdba',
          borderColor:'#ecbdba',
          textColor:'#fff',
          weddingSchedule: matchingSlot || WeddingSchedule[UnspecifiedScheduleId]
        }

        axios.post(`${process.env.REACT_APP_API}/requests/create-new-request`, payload)
        .then(() => {
          if (userResponse.status === 201) {
            //toast.success("Successfully sent request and created account")
            navigate('/success')
          } else { // should be 200
            //toast.success("Successfully sent request")
            navigate('/success')
          }
          setLoading(false)

          // clear the form once everything successful
          setDate("");setNotes("");setActType("");setPerformanceSlot("");setStartTime("");setEndTime("");setVenue("");setLocation("")
          // only clear the email & name if this not a logged in user
          if (!currentUser) {
            setName("");
            setEmail("");
          }
        })
        .catch((error) => {
          // check special backend codes
          if (
            (error?.response?.status === 409) &&
            (error?.response?.data?.subCode === 'DIFFERENT_DATE')
          ) {
              window.alert(
              `${error.response.data.message}\n\n` +
              "Please use the chat button to contact support and discuss your options."
            )
          } else {
            toast.error("Error submitting request")
          }

          setLoading(false)
        })
      })
      .catch((error) => {
        if (error.response?.data?.message) {
          // display a specific server error message
          toast.error(error.response.data.message);
        } else {
          // handle other errors
          toast.error("There was a problem submitting your request. Please try again.");
        }

        setLoading(false)
      })
    }
  }


  return (
    <div id='find-page' >
      <div style={{width:600}}>
        <h1 id='post-header'>Post a wedding request - We'll take care of the rest</h1>
        <p style={{color: 'grey', fontSize:20, fontWeight: 600}}>Get responses from First Dance Music's tried and trusted musicians</p>
      </div>

      <div>

      <div className='enquire-container' >

        <label style={{marginBottom: 5}}>Date of wedding</label>
        <input
            className='single-input'
            style={{ color: !date ? '#B5B5B5' : null }}
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
        />

        <div className='vertical-spacer' />

        <input
            className='top-input'
            type='text'
            placeholder='Full name'
            value={name}
            disabled={currentUser}
            onChange={(e) => setName(e.target.value) }
        />
        <input
            className='bottom-input'
            type='text'
            placeholder='Email address'
            value={email}
            disabled={currentUser}
            onChange={(e)=>setEmail(e.target.value)}
        />

        <div className='vertical-spacer' />

        <div style={{display:'flex', justifyContent:'space-between'}}>
            <label>Music start time (estimate)</label>
            <label style={{marginLeft: 10}}>Music end time (estimate)</label>
        </div>

        <div style={{width: '100%', display:'flex', justifyContent: 'space-between', alignItems:'center'}}>
            <input
              aria-label="Time"
              className='left-input'
              style={{ color: !startTime ? '#B5B5B5' : null }}
              type="time"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
            />

            <input
              aria-label="Time"
              className='right-input'
              style={{ color: !endTime ? '#B5B5B5' : null }}
              type="time"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
            />
        </div>

        <div className='vertical-spacer' />

        <input
            className='top-input'
            type="text"
            value={venue}
            onChange={(e)=>setVenue(e.target.value)}
            placeholder='Venue name'
        />

        <select
            className='middle-input'
            value={location}
            onChange={(e) => setLocation(e.target.value)}
        >
            <option value="">
                Select location
            </option>
            {Locations.map((location) => (
                <option key={location} value={location}>
                  {location}
                </option>
                ))
            }
        </select>

        <select
            className='middle-input'
            value={actType}
            onChange={(e) => setActType(e.target.value)}
        >
            <option value="" disabled>
                Select act type
            </option>
            {ActTypes.map((type, i) => (
                <option key={i} value={type.name}>
                  {type.name}
                </option>
              ))
            }
        </select>

        <select
            className='bottom-input'
            value={performanceSlot}
            onChange={(e) => setPerformanceSlot(e.target.value)}
        >
            <option value="">
              Select performance slot (optional)
            </option>
            {Timings.map((type, i) => (
                <option key={i} value={type.name}>
                  {type.name}
                </option>
              ))
            }
        </select>

        <div className='vertical-spacer' />


        <textarea
        style={{resize: 'none', height: 150, padding: 10}}
            className='single-input'
            placeholder='Notes e.g. drinks reception, first dance, start times etc'
            value={notes}
            onChange={(e)=>setNotes(e.target.value)}
        />

        <div className='vertical-spacer' />


      <button className='modal-btn' onClick={handleSubmit} disabled={loading}>
        {loading ? 'Requesting...' : "Request quote"}
      </button>

      <p>
            <small>
              <em>
                By submitting this form you agree to us creating an account for you with the provided email.<br />
                This will only be used for the purpose of your booking and its removal can be requested at any time.
              </em>
            </small>
          </p>


      </div>
    </div>
    </div>
  )
}
