import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../Context/AuthContext'
import axios from 'axios'
import { toast } from 'react-toastify'
import close from '../../../Assets/Icons/close.png'
import { Locations } from '../../../Constants';

export default function ModalActFind( { setOpen } ) {

  let { currentUser } = useAuth()
      // modal state
  const [name, setName ] = useState("")
  const [email, setEmail ] = useState("")
  const [date, setDate ] = useState("")
  const [number, setNumber ] = useState("")
  const [location, setLocation ] = useState("")
  const [notes, setNotes ] = useState("")
  const [loading, setLoading ] = useState(false)
  const [sent, setSent ] = useState(false)

  useEffect(() => {
    if (currentUser?.email) {
      setName(currentUser.displayName);
      setEmail(currentUser.email);
    }
  }, [currentUser]);

  const handleContainerClick = (e) => {
    e.stopPropagation(); // Prevents click event from bubbling up to the modal div
  };

  const handleSubmit = () => {
    let payload = {
        date, name, email, number, location, notes
    }

    if(!date || !name || !email || !number || !location || !notes){
        toast.error("Please add all fields")
    } else {
        setLoading(true)

        axios.post(`${process.env.REACT_APP_API}/requests/act-find-request`, payload)
        .then(() => {
            setSent(true)
        })
        .catch((e) => {
            toast.error("Error submitting")
        })
        .finally(() => {
            setLoading(false)
        })
    }



  }

  return (
    <div className='modal' onClick={(e) =>  setOpen(false)}>

      <div className='modal-container' onClick={handleContainerClick}>
        <img className='hover close-modal-btn' src={close} alt='Close First Dance Music find box' onClick={() => setOpen(false)}/>

        <h3 className='modal-header'>{sent ? "Success!" : "Act Finder"}</h3>

        {sent ?
            <div>
                <p>Thanks for submitting your request!</p>

                <p>We have now been notified and will be in touch soon for some updates</p>
            </div>

            :
            <>

            <p>At First Dance Music we have access to over 500 musicians who aren't yet on the platform.</p>

            <p>If it's bagpipes or a flautist you're after, we're confident we will be able to find the perfect musician for your big day!</p>

          <label style={{marginBottom: 5}}>Wedding date</label>
          <input
              className='single-input'
              style={{ color: !date ? '#B5B5B5' : null }}
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
          />

          <div className='vertical-spacer' />

          <input
              className='top-input'
              type='text'
              placeholder='Full name'
              value={name}
              disabled={currentUser}
              onChange={(e) => setName(e.target.value) }
          />
          <input
              className='middle-input'
              type='email'
              placeholder='Email address'
              value={email}
              disabled={currentUser}
              onChange={(e)=>setEmail(e.target.value)}
          />
           <input
              className='bottom-input'
              type='text'
              placeholder='Contact Number'
              value={number}
              onChange={(e)=>setNumber(e.target.value)}
          />

          <div className='vertical-spacer' />

          <select
              className='single-input'
              value={location}
              onChange={(e) => setLocation(e.target.value)}
          >
              <option value="">
                  Select wedding location
              </option>
              {Locations.map((location) => (
                  <option key={location} value={location}>
                    {location}
                  </option>
                  ))
              }
          </select>

          <div className='vertical-spacer' />

          <textarea
              style={{resize: 'none', height: 200, padding: 10}}
              className='single-input'
              placeholder="Please tell us what you're looking for eg. A String Quartet as we are walking down the aisle."
              value={notes}
              onChange={(e)=>setNotes(e.target.value)}
          />

          <div className='vertical-spacer' />

          <button className='modal-btn' onClick={handleSubmit} disabled={loading}>
            {loading ? 'Submitting...' : "Submit"}
          </button>

          </>
        }

        </div>
    </div>
  )
}
