import React, { useEffect, useState} from 'react'
import axios from 'axios'
import Avatar from '@mui/material/Avatar';
import moment from 'moment'
import '../Inbox.css'
import { useNavigate } from 'react-router-dom';

export default function AllInbox() {
  let navigate = useNavigate()
  const [chats, setChats] = useState([])

  useEffect(() => {
    getChats()
  },[])

  const getChats = () => {
    axios.get(`${process.env.REACT_APP_API}/chats/get-all-chats`,)
      .then((res) => {
        setChats(res.data)
      })
      .catch((error) => console.log(error))
  }

  return (
    <div id='inbox-page'>
      <div id='inbox-container'>
        {chats.length < 1 ? (
          <p style={{ textAlign: 'center' }}>Inbox empty</p>
        ) : (
          chats
            .slice() // Create a shallow copy of the array to avoid mutating the original
            .sort(function(a, b) {
              return new Date(b.lastMsgAdded) - new Date(a.lastMsgAdded);
            })
            .map((chat, i) => {
              return (
                <>
                  {!chat.ticketId &&
                    <div key={i} className='inbox-row' onClick={() => navigate(`/artists/admin/all-inbox/${chat._id}`)}>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <div style={{ display: 'flex', position: 'relative', width: '100%' }}>
                          <Avatar sx={{ width: 50, height: 50, mr: 3 }}>{chat.artistName?.charAt(0)}</Avatar>
                          <p className='inbox-name' style={{ fontWeight: (chat.artistRead === false) ? 'bold' : 'normal' }}>{chat.artistName}</p>

                          <div style={{ position: 'absolute', left: '500px', display: 'flex', alignItems: 'center' }}>
                            <Avatar sx={{ width: 50, height: 50, mr: 3 }}>{chat.clientName?.charAt(0)}</Avatar>
                            <p className='inbox-name' style={{ fontWeight: (chat.clientRead === false) ? 'bold' : 'normal' }}>{chat.clientName}</p>
                          </div>
                        </div>
                        <p style={{ textAlign: 'right', width: '100px', whiteSpace: 'nowrap' }}>{moment(chat.lastMsgAdded).fromNow()}</p>
                      </div>
                    </div>
                  }
                </>
              )
            })
        )}
      </div>
    </div>
  )
}
