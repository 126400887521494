import React from 'react'
import disco from '../../Assets/success.webp'

export default function SuccessfulPaymentPage() {
  return (
    <div className='success-page'>

    <div style={{display:'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', alignItems: 'center', width: 1200, maxWidth: '100vw', margin: '2rem 0'}}>
        <div style={{ width: 600, maxWidth: '90vw'}}>
            <h1>All Booked!</h1>
            <p>We want to say a big thank you for choosing First Dance to help find entertainment for your special day.</p>

            <p>Your booking has been successfully made so you can relax knowing that you have ticked another thing off the list!</p>
        </div>
        <img src={disco} id='disco-ball' alt='First Dance Music successfully booked wedding music'/>
    </div>


    <p style={{maxWidth: 1100, fontSize:16}}>By now you should have received a confirmation email with your booking details. You can log in at any time to view your booking and contact your musician by using our direct messaging tool.</p>

    <p style={{maxWidth: 1100, fontSize:16}}>If you experience any issues or have questions, please get in touch via our support tool or email <span style={{color: '#ecbdba'}}>info@firstdancemusic.co.uk</span></p>
</div>
  )
}
