import React, { useEffect, useState} from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import { loadStripe } from "@stripe/stripe-js";
import YouTube from 'react-youtube';
import { Elements } from "@stripe/react-stripe-js";
import { ExtractEmbedFromVideoUrl } from '../../Utilities.js'
import { LogToServer } from '../../Utilities'
import PaymentForm from "./PaymentForm.js"
import './Quote.css'
import ClientFAQ from './ClientFAQ.js';

// Display summarised quote information, along with original request, to a client
// Payment button is context sensitive. If payment has not yet been made then the
// button is displayed which will open a child form detailing one-off or payment
// plan along with credit card entry. Completion of payment returns to the parent
// closing the child, updating db and sending confirmation emails. Page now shows
// as paid whenever it is rendered.
export default function Quote() {

    let { id } = useParams()
    let navigate = useNavigate()
    const [artist, setArtist] = useState("")
    const [request, setRequest] = useState("")
    const [quote, setQuote] = useState("")
    const [act, setAct] = useState("")
    const [customerGross, setCustomerGross] = useState(0)
    const [showPaymentForm, setShowPaymentForm] = useState(false);

    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

    useEffect(() => {
        getQuoteInfo()
    }, [])

    useEffect(() => {
        // get the request and artist info for the quote
        if (quote) {
            getRequest()
            getArtistInfo()
            getActInfo()
        }
    }, [quote])

    const handlePaymentSuccess = (paymentData) => {
        // payment has just been made so create the booking from the request
        console.log(paymentData.message)

        // remove the payment form
        setShowPaymentForm(false)

        updateQuoteInfo()
        createBooking(paymentData.stripeCustomerId)
        updateAndNotifyBookedRequest(paymentData.paymentSummary)
        notifyQuotingArtists()

        LogToServer('Booking and purchase complete, navigating to success page.')

        //Navigate to success page for analytics
        navigate('/payment-success')
    };

    const updateQuoteInfo = async () => {
        try {
            const updatedQuote = { ...quote, hasPaid: true };
            const updatedData = await axios.put(`${process.env.REACT_APP_API}/quotes/update-quote/${id}`, { updatedQuote });
            // best not to hang around for the put, update the local quote state directly to ensure re-render
            setQuote((prevQuote) => ({ ...prevQuote, hasPaid: true }));
        } catch (error) {
            console.log("Error updating quote", error);
        }
    };

    const createBooking = (stripeCustomerId) => {

        let payload = {
            title: `${request.name} - ${request.info.actType}`,
            artistId: artist._id,
            requestId: request._id,
            stripeCustomerId,
            clientId: request.clientId,
            date: moment(request.date).format("YYYY-MM-DD"),
            notes: request.notes || "",
        }
        axios.post(`${process.env.REACT_APP_API}/bookings/create-new-booking`, payload)
        .then(() => {
            console.log("Booking created for artist")
        })
        .catch(error => {
            console.log(error);
        });
    }

    const updateAndNotifyBookedRequest = async (paymentSummary) => {
        // Update the request with the quote Id and artist Id to bind them
        // If this was from a direct artist request the artist Id may well
        // already have been present
        const updatedRequest = { ...request, quoteId: quote._id, artistId: artist._id, fulfilled: true };
        try {
            const res = await axios.put(`${process.env.REACT_APP_API}/requests/update-request`, { updatedRequest });
            console.log("Request updated with Ids for artist and quote");
            setRequest(res.data);

            // send notifications
            // ideally this would be done even if the update above failed but safer to keep quiet until
            // whatever failed there is fixed. Any failure there will be present in system info of admin panel
            axios.post(`${process.env.REACT_APP_API}/bookings/send-booking-confirmations`, { request: res.data, artist, paymentSummary } )
            .then(() => {
                console.log("Booking confirmation emails sent to client and artist")
            })
            .catch(error => {
                console.log(error);
            });
        } catch (error) {
            console.log(error);
        }
    }

    // Any artist who has already engaged this client by providing a quote
    // should be notified that the request is no longer available. Since a
    // quote is initiated along with an inbox, this can be done by auto
    // appending a message to the chat
    const notifyQuotingArtists = async () => {
        axios.post(`${process.env.REACT_APP_API}/chats/notify-quoting-artists`, { requestId: request._id, artistId: artist._id } )
        .then(() => {
            console.log("Artists who have provided quotes have now been notified")
        })
        .catch(error => {
            console.log(error);
        });
    }

    const getRequest = () => {
        axios.post(`${process.env.REACT_APP_API}/requests/get-request-by-id`, {id: quote.requestId})
        .then((res)=>{
            setRequest(res.data)
            LogToServer(`Client ${res.data.name} is viewing quote ${id} for amount ${quote.customerGross}`)
        })
        .catch((error) => console.log(error))
    }

    const getArtistInfo = () => {
        axios.post(`${process.env.REACT_APP_API}/users/get-artist-by-id`, {id: quote.artistId})
        .then((res) => {
            setArtist(res.data);
        })
        .catch((error) => console.log(error))
    }

    const getQuoteInfo = async () => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_API}/quotes/get-quote-by-id`, {id});
            setQuote(res.data);

            // ! Payment Test Case !
            // can make a test booking for 30p which is the stripe min
            if (res.data.customerGross === "0.3") {
                setCustomerGross(parseFloat(res.data.customerGross))
            } else {
                setCustomerGross(Math.round(res.data.customerGross * 100) / 100)
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getActInfo = () => {
        axios.post(`${process.env.REACT_APP_API}/acts/get-act-by-id`, {actId: quote.selectedActId})
        .then((res) => {
            setAct(res.data);
        })
        .catch((error) => console.log(error))
    }

    const freeBooking = () => {
        // this is a free booking
        LogToServer(`Zero cost booking being made for client ${request.clientId}`)

        const stripeCustomerId = "dummy" + request.clientId
        const paymentSummary = `Zero cost booking being made for ${request.clientId}`

        const paymentData = {
            message: 'Free Booking Successfully Made',
            stripeCustomerId,
            paymentSummary
        }

        handlePaymentSuccess(paymentData)
    }

    const continueToPayment = () => {
        setShowPaymentForm(true)
    }

    const opts = {
        width: '300',
        height: '200',
        playerVars: {
          controls: 1,
          modestbranding: 1,
          rel: 0,
          fs: 1,
        },
    };

    return (
        <div className='client-outlet' >
            <div style={{display:'flex', flexDirection: 'row', justifyContent:'space-evenly', alignItems:'center', flexWrap:'wrap'}}>
                <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '500px', whiteSpace: 'normal' }}>
                    <h2>Wedding details</h2>
                    <p>Client name - {request && request.name}</p>
                    <p>Date of wedding - {moment(request.date).format('ddd, DD MMM YYYY')}</p>
                    <p>Act requested - {request && request.info.actType}</p>
                    <p>Start time - {request && request.info.startTime}</p>
                    <p>End time - {request && request.info.endTime}</p>
                    <p>Venue - {request && request.info.venue}</p>
                    <p>Location - {request && request.info.location}</p>
                    <p>Notes - {request.notes}</p>
                </div>
                <div style={{width: 500, textAlign:'center', maxWidth:'95vw'}}>
                    {quote.hasPaid ? (
                        <h2>Congratulations! Your wedding music is booked</h2>
                    ) : (request.fulfilled ?
                            <>
                                <h2 style={{marginBottom: '0px'}}>You accepted an alternative act</h2>
                                <p style={{ fontSize: '12px', marginTop: '0px', marginBottom: '20px' }}>
                                    Please check your My Wedding page
                                </p>
                            </>
                        :
                            <h2>Your Wedding Music Quote</h2>
                    )}
                    {act._id ? (
                            <a href={`/find/artists/${act._id}`} target="_blank" rel="noopener noreferrer" style={{color: '#ecbdba', fontWeight: 900}}>
                               <h3>View act profile</h3>
                            </a>
                    ) : (
                        <>
                            The artist did not provide a specific act in their quote.
                            We recommend you let them know in the chat and meantime
                            it might be useful for you to browse{" "}
                            <a href="/find/artists" target="_blank" rel="noopener noreferrer">
                                here
                            </a>.
                        </>
                    )}

                    {act.video && (
                        <div>
                            <YouTube
                                videoId={ExtractEmbedFromVideoUrl(act.video)}
                                opts={opts}
                                onPlay={(e) => e.target.setPlaybackQuality('small')}
                            />
                        </div>
                    )}

                    <h3 style={{color: '#edbcba', fontWeight: 900}}>Quote - £{customerGross}</h3>
                    <textarea
                        style={{
                            width: '100%',
                            resize: 'none',
                            height: 200,
                            padding: '1rem',
                            color: '#313131',
                            borderRadius: 8,
                            borderColor: '#dfdfdf',
                            fontSize:"16px"
                        }}
                        value={quote.message}
                        disabled
                    >
                    </textarea>

                    {quote.hasPaid ? (
                        <div>Payment for this booking has been made and a confirmation email sent to your provided email address.</div>
                    ) : (customerGross === 0 ? (
                            <button id="quote-btn"
                                style={{ fontSize: '16px', padding: '10px 20px' }}
                                onClick={freeBooking}
                            >
                                Free Booking
                            </button>
                        ) : (!request.fulfilled && !showPaymentForm && (
                            <button id="quote-btn"
                                style={{ fontSize: '14px', padding: '10px 20px' }}
                                onClick={continueToPayment}
                            >
                                Continue to Payment
                            </button>
                        )
                    ))}

                    <p>If you have any questions you can contact the musician via the <Link to={'/clients/inbox'} style={{color: '#ecbdba', fontWeight: 900}}>inbox</Link></p>

                </div>
            </div>
            <div>
                {showPaymentForm && (
                    <div>
                        <Elements stripe = {stripePromise}>
                            <PaymentForm
                                request={request}
                                customerGross={customerGross}
                                quote={quote}
                                onSuccess={handlePaymentSuccess}
                            />
                        </Elements>
                    </div>
                )}
            </div>
            <ClientFAQ />

        </div>
    )
}
