import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import { UseInbox } from '../../Context/InboxContext'


// public chat for messages from potential client to support
export default function PublicChat() {

    let { getUnreadChats } = UseInbox()
    let { userId, id } = useParams()
    let navigate = useNavigate()

    const [messages, setMessages] = useState([])
    const [message, setMessage] = useState("")
    const [chat, setChat] = useState("")

    const messagesEndRef = useRef(null)

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        scrollToBottom()
    }, [messages])

    useEffect(() => {
        getChat()
    }, [])

    useEffect(() => {
        getMessages()
    }, [chat])

    const getChat = () => {
        // public chat user is actually stored as client in
        // the chat schema but with a user type of Unregistered
        axios.post(`${process.env.REACT_APP_API}/chats/get-client-chat-by-id`, {
            chatId: id,
            //userId: userId, TODO : need session timeout and verification
        })
        .then((res) => {
            setChat(res.data)
        })
        .catch((error) => console.log("Error getting client chat by id", error))
    }

    const getMessages = () => {
        axios.post(`${process.env.REACT_APP_API}/chats/get-messages-by-chat-id`, {chatId: chat._id})
        .then((res) => {
            setMessages(res.data)
            // getUnreadChats() TBD cannot use for public as no firebase user
        })
        .catch((error) => console.log("Error getting public messages by chat id", error))
    }

    const sendMessageToSupport = () => {
        if (!message) {
            return
        } else {
            axios.post(`${process.env.REACT_APP_API}/chats/send-message-to-adminsupport`, {
                chatId: chat._id,
                message,
                senderName: chat.clientName,
                senderEmail: chat.clientEmail,
                sentBy: "Unregistered"
            })
            .then((res) => {
                setMessage("")
                getMessages()
                console.log(res.data)
            })
            .catch((error) => console.log("Error sending message to admin support", error))
        }
    }

    return (
        <div id='chat-page'>
            {!chat ? (
                <h2 style={{textAlign:'center', marginBottom: 0}}>No chats found </h2>
            ) : (
                <>
                    <button onClick={() => navigate(`/inbox/${userId}`)} style={{ textAlign: 'left' }}>Back to chat list</button>
                    <h2 style={{ textAlign: 'center', marginBottom: 0 }}>Support ticket</h2>
                    <div style={{ textAlign: 'center', marginTop: '5px', paddingBottom: '20px' }}>
                        {chat && <em>(Full reference: {chat.ticketId})</em>}
                    </div>

                    {messages.map((msg) => {
                        let lines = msg.message.split('\n')

                        return (
                            <div key={msg._id} className={msg.sentBy === "Unregistered" ? 'own-msg-container' : undefined}>
                                <div className={msg.sentBy === "Unregistered" ? 'own-msg' : 'other-msg'}>
                                    {lines.map((line, index) => {
                                        return (
                                            <p key={index}>
                                                <React.Fragment key={index}>
                                                    {line} <br />
                                                </React.Fragment>
                                            </p>
                                        )
                                    })}

                                    <p className='timestamp'>{moment(msg.timeStamp).format('HH:mm A, DD MMM')}</p>
                                </div>

                                {(msg === messages[messages.length - 2]) && (
                                    <div ref={messagesEndRef}> </div>
                                )}
                            </div>
                        )
                    })}

                    <div id='message-container'>
                        <textarea
                            id='msg-box'
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder='Enter your message'
                        />
                        <button id='send-btn' onClick={sendMessageToSupport}>Send to support</button>
                    </div>
                </>
            )}
        </div>
    )
}
