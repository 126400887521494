import React, { useState } from 'react'
import './ClientFAQ.css'
import hearts from '../../Assets/bg-hearts.png'
import { Link } from 'react-router-dom'

let questions = [
    {q: "How does the payment plan work?", a: "Enjoy the convenience of securing your musician with an initial deposit. Spread the remaining cost over time leading up to your wedding day, with payments completed one month before your special date. Our 0% spread the cost option makes budgeting stress-free and all with no credit checks. Minimum booking value £300." },
    { q: "Can I cancel my booking?", a: <>Our full cancellation policy can be found in our <a href='/client-terms' target='_blank' style={{fontWeight: 700, color:'#313131'}}>Terms & Conditions</a> under clause 3.</> },
    {q: "Are there any hidden fees?", a: "No hidden fees! Our transparent pricing policy ensures there are no surprises. The price you see is the price you pay."},
    {q: "Is there a minimum or maximum booking timeframe?", a: "We understand that every wedding is unique. Whether you need music for a few hours or the entire day, we'll tailor our services to fit your schedule."},
    {q: "What if I need to make changes to my booking?", a: "Flexibility is key! If you need to make changes to your booking, simply reach out to our friendly team using our support chat. We'll assist you every step of the way, ensuring your experience is stress-free."}
]

export default function ClientFAQ() {

    const [open, setOpen] = useState("")

    const handleClick = (index) => {
        if(open === index){
            setOpen("")
        } else {
            setOpen(index)
        }
    }

  return (
    <div id='client-faqs-container'>
        <h2>Frequently Asked Questions</h2>
        <img id='bg-hearts'  src={hearts}/> 
        {questions.map((item, i) => {
                return (
                    <div key={i} className={open === i ? 'faq-container-open' : 'faq-container'} onClick={() => handleClick(i)}>
                        <div style={{display:'flex', alignItems: 'center'}}>
                            <div className={`icon ${open === i ? 'open' : ''}`}></div>

                            <h6>{item.q}</h6>
                        </div>
                        <p className={open === i ? 'open-answer' : 'close-answer'} >{item.a}</p>
                    </div>
                )
            })}
    </div>
  )
}
