import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import { UseInbox } from '../../../Context/InboxContext'
import '../../CommonChat.css'

// admin viewer for all chats
export default function AllChat() {

    let { id } = useParams()
    let { getUnreadChats } = UseInbox()

    const [messages, setMessages] = useState([])
    const [chat, setChat] = useState("")

    const messagesEndRef = useRef(null)

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        scrollToBottom()
    }, [messages]);

    useEffect(() => {
        getChat()
        getMessages()
    },[])

    const getChat = () => {
        // while this is not an admin chat, this endpoint will allow admin to pick up a chat
        axios.post(`${process.env.REACT_APP_API}/chats/get-admin-chat-by-id`, {chatId: id})
        .then((res) => {
            setChat(res.data)
        })
        .catch((error) => console.log(error))
    }

    const getMessages = () => {
        axios.post(`${process.env.REACT_APP_API}/chats/get-messages-by-chat-id`, {chatId: id})
        .then((res) => {
            setMessages(res.data)
        })
        .catch((error) => console.log(error))
    }


    const renderMessage = (msg, i) => {
        const lines = String(msg.message).split("\n")

        return (
            <div key={`msg-${i}`}>
                {lines.map((line, index) => {
                    const quoteRegex = /Quote ([a-fA-F0-9]{24})/
                    const actRegex = /For proposed act (.*?): (.*)/
                    const quoteMatch = line.match(quoteRegex)
                    const actMatch = line.match(actRegex)
                    if (quoteMatch) {
                        const requestURL = `/artists/requests/${chat.requestId}`
                        return (
                            <p key={`quote-${i}-${index}`}>
                                <a
                                    style={{fontFamily: 'Mont', color:'grey'}}
                                    href={requestURL} target="_blank" rel="noopener noreferrer"
                                >
                                    View or amend the quote
                                </a>{" "}
                                you provided to the client
                            </p>
                        )
                    } else if (actMatch) {
                        const prefix = line.split(actMatch[1])[0]
                        const actURL = `/find/artists/${actMatch[2]}`
                        return (
                            <p key={`act-${i}-${index}`}>
                                <span>{prefix}</span>
                                <a
                                    style={{fontFamily: 'Mont', color:'grey'}}
                                    href={actURL} target="_blank" rel="noopener noreferrer"
                                >
                                    {actMatch[1]}
                                </a>
                            </p>
                        )
                    } else {
                        return (
                            <React.Fragment key={`line-${i}-${index}`}>
                                {line} <br />
                            </React.Fragment>
                        )
                    }})
                }
            </div>
        )
    }

    return (
        <div id='chat-page'>
            {messages.map((msg, i) => {
                return <div key={`container-${i}`} className={msg.sentBy === "Artist" ? 'own-msg-container' : undefined}>
                    <div key={`msg-${i}`} className={msg.sentBy === "Artist" ? 'own-msg' : 'other-msg'}>

                        {renderMessage(msg, i)}

                        <p className='timestamp'>
                            {moment(msg.timeStamp).format('HH:mm A, DD MMM')}
                            {chat.ticketId && (
                                <>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <em>Support reference: {chat.ticketId.slice(-10)}</em>
                                </>
                            )}
                        </p>
                    </div>

                    {(i === messages.length - 2) && (
                        <div ref={messagesEndRef}> </div>
                    )}
                </div>
            })}

        </div>
    )
}
