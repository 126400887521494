
import React, { useState } from 'react'
import './Modal.css'
import close from '../../../Assets/Icons/close.png'
import { toast } from 'react-toastify'
import axios from 'axios'
import { ActTypes, Locations } from '../../../Constants'

export default function ModalSignUp({setOpen, setOpenLogin}) {

  //Form state
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [number, setNumber] = useState("")
  const [actType, setActType] = useState("")
  const [location, setLocation] = useState("")
  const [video, setVideo] = useState("")
  const [loading, setLoading] = useState(false)
  const [sent, setSent] = useState(false)

  const handleContainerClick = (e) => {
    e.stopPropagation(); // Prevents click event from bubbling up to the modal div
  };

  const handleSwitch = () => {
    setOpen(false)
    setOpenLogin(true)
  }

  const handleSubmit = () => {
    let payload = {
      firstName,
      lastName,
      email,
      number,
      video,
      actType,
      location
    }
    if(!firstName || !lastName || !email || !number || !video || !actType || !location){
      toast.error("Please add all fields")
    } else {
      setLoading(true)
      axios.post(`${process.env.REACT_APP_API}/acts/signup-request`, payload)
      .then(() => {
        setSent(true)
      })
      .catch(() => {
        toast.error("Something went wrong. Please use our support tool.")
      })
      .finally(() => {
        setLoading(false)
      })

    }
  }

  return (
    <div className='modal' onClick={(e) =>  setOpen(false)}>

        <div className='modal-container' onClick={handleContainerClick}>
          <img className='hover close-modal-btn' src={close} alt='Close First Dance Music signup box' onClick={() => setOpen(false)}/>

          <h3 className='modal-header' style={{color: '#313131'}}>Artist Sign Up</h3>

          {sent ?
          <div style={{textAlign:'center'}}>
            <h4 style={{color: 'green', fontFamily: 'Mont'}}>Successfully sent!</h4>
            <p style={{color: '#313131'}}>Thanks for submitting your request. If we are currently onboarding acts within your category and location we will be in touch within 3 working days.</p>
          </div>
          :

          <>

            <p style={{color: '#313131'}}>We onboard artists depending on category, location and current customer demand.</p>
            <p style={{color: '#313131'}}>Please fill out your details below and if we have capacity, you will receive an email within 3 working days outlining the next steps.</p>

            <div className='vertical-spacer' />

            <input className='top-input' type='text' placeholder='First name' value={firstName} onChange={(e)=> setFirstName(e.target.value) }/>
            <input className='bottom-input' type='text' placeholder='Last name' value={lastName} onChange={(e)=> setLastName(e.target.value) }/>

            <div className='vertical-spacer' />

            <input className='top-input' type='email' placeholder='Email address' value={email} onChange={(e)=> setEmail(e.target.value) }/>
            <input className='bottom-input' type='text' placeholder='Contact number' value={number} onChange={(e)=> setNumber(e.target.value) }/>

            <div className='vertical-spacer' />

            <input className='top-input' type='text' placeholder='Video Link eg. Youtube' value={video} onChange={(e)=> setVideo(e.target.value) }/>
            <select className='middle-input' type='text' value={actType} onChange={(e)=> setActType(e.target.value) }>
              <option value="" disabled>Select Primary Act Type</option>
              {ActTypes.map((act, i) => {
                return (
                  <option key={i} value={act.name}>{act.name}</option>
                )
              })}
            </select>
            <select className='bottom-input' type='text' value={location} onChange={(e)=> setLocation(e.target.value) }>
              <option value="" disabled>Select Primary Location</option>
              {Locations.map((area, i) => {
                return (
                  <option key={i} value={area}>{area}</option>
                )
              })}
            </select>


          <button className='modal-btn' onClick={handleSubmit} disabled={loading}>
            {loading ? 'Please wait...' : "Sign Up Request"}
          </button>

          <p style={{color: '#313131'}}>Already have an account? <span className='hover' style={{textDecoration: 'underline'}} onClick={handleSwitch}>Login</span></p>

          </>}

        </div>

    </div>
  )
}