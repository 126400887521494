import React, { useState, useEffect} from 'react'
import axios from 'axios'
import moment from 'moment'
import { useNavigate, Link } from 'react-router-dom'
import './RequestList.css'
import { useAuth } from '../../Context/AuthContext'
import { ActTypes } from '../../Constants'
import discoBall from '../../Assets/Icons/disco-ball.png'
import filter from '../../Assets/Icons/filter.png'

export default function RequestList() {

    let { currentUser } = useAuth()

    useEffect(() => {
        getRequests()
    }, [])

    

    const [requests, setRequests] = useState([])
    const [filtered, setFiltered] = useState([])
    const [filterOption, setFilterOption] = useState("All")
    const [showOptions, setShowOptions] = useState(false)

    useEffect(() => {

        if(filterOption === "All"){
            setFiltered(requests)
        } else {
            let filter = requests.filter((request) => filterOption == request.info?.actType)
            setFiltered(filter)
        }


    }, [filterOption])

    const getRequests = () => {
        axios.post(`${process.env.REACT_APP_API}/requests/get-all-requests-filtered`, {
            artistId: currentUser.uid
        })
        .then((res) => {
            //This eliminates any quotes that are onPause but that an artist hasn't aready engaged with
            let filtered = res.data.filter((request) => {  return !(deriveRequestStatus(request) === "Not Quoted" && request.onPause === true);})
            let sorted = filtered.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            setRequests(sorted)
            setFiltered(sorted)
        })
        .catch((e) => console.log(e))
    }

    const deriveRequestStatus = (request) => {
        if ((request.fulfilled === true) && (request.artistId === currentUser.uid)) {
            return <p style={{color: '#C1E1C1', fontWeight: 'bold', margin: '5px 0'}}>Booked</p>
        } else if (request.quoted.includes(currentUser.uid)) {
            return <p style={{color: '#C1E1C1', fontWeight: 'bold', margin: '5px 0'}}>Quoted</p>
        }  else {
            return <p style={{color: '#ff746c', fontWeight: 'bold', margin: '5px 0'}}>Not Quoted</p>
        }
    }

    const deriveHighlight = (request) => {
        if ((request.fulfilled === true) && (request.artistId === currentUser.uid)) {
            return <div className='green-highlight'/>    //Booked

        } else if (request.quoted.includes(currentUser.uid)) {
            return <div className='green-highlight'/>    //Quoted
        }  else {
            return <div className='red-highlight'/>    //Not Quoted
        }
    }

    const handleFilter = (option) => {
        setFilterOption(option)
        setShowOptions(false)
    }

    return (
        <div  id='request-list-page'>

            <div className='hover' id='request-filter-icon'>
                <img 
                    style={{height: 30, width: 30}}  
                    src={filter} 
                    alt='First Dance Music Wedding Filter'
                    onClick={() => setShowOptions(!showOptions)}
                />
                {showOptions && 
                    <div id='filter-option-popover'>
                        <p className='filter-list-option'onClick={() => handleFilter("All")}>View All</p>
                        {ActTypes.map((act, i) => {
                            return (
                                <p className='filter-list-option' onClick={() => handleFilter(act.name)} key={i}>{act.name}</p>
                                )
                        }
                        )}
                    </div>
                }
            </div>
          
          

            <div id='request-list-container'>
            {filtered.length < 1 ? (
                        <tr>
                            <td colSpan={7} style={{textAlign:'center', paddingTop: 50}}>
                                No requests available
                            </td>
                        </tr>
                    ) : (
                        filtered
                            .map((request, i) => {
                        
                                let icon = ActTypes.find((act) => act.name == request.info?.actType)

                                return (
                                        <Link to={`/artists/requests/${request._id}`} key={i} className='artist-request-container' style={request.onPause ? {backgroundColor: '#f1f1f1', textDecoration: 'none'} : {textDecoration: 'none'}}>
                                            {deriveHighlight(request)}

                                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                <h2 style={{fontWeight: 900, marginTop: 0, fontSize: 20}}>Request for {request.info.actType} {request.onPause? "(Paused)" : ""}</h2>
                                                <img style={{width: '15%', marginLeft: 10, objectFit: 'contain'}} src={icon?.icon || discoBall} alt='First Dance Music Wedding Icon'/>
                                            </div>

                                            <div>
                                                <p className='request-info-header'>DATE: <span style={{fontWeight:700, letterSpacing: 0}}>{moment(request.date).format('dddd Do MMMM YYYY')}</span></p>
                                                <p className='request-info-header'>VENUE: <span style={{fontWeight:700, letterSpacing: 0}}>{request.info.venue}</span></p>
                                                <p className='request-info-header'>LOCATION: <span style={{fontWeight:700, letterSpacing: 0}}>{request.info.location}</span></p>

                                            </div>

                                            {deriveRequestStatus(request)}
                                        </Link>
                                );
                            })
                    )}
            </div>

        </div>
    )
}
