import React from 'react'
import { Link, Outlet } from 'react-router-dom'
import Badge from '@mui/material/Badge';
import { UseInbox } from '../../../Context/InboxContext';
import './AdminDashboard.css'

export default function AdminDashboard() {

    let { unreadSupportChats, allUnreadChats } = UseInbox()

  return (
    <div className='business-outlet'>
                <div id='admin-nav'>
                  <Link className='admin-nav-link' to='/artists/admin/list'>
                    Artists
                  </Link>
                  <Link className='admin-nav-link' to='/artists/admin/act-list'>
                    Admin Acts
                  </Link>
                  <Link className='admin-nav-link' to='/artists/admin/bookings'>
                    Bookings
                  </Link>
                  <Link className='admin-nav-link' to='/artists/admin/all-requests'>
                    All Requests
                  </Link>
                  <Badge badgeContent={unreadSupportChats?.length}  color="error">
                    <Link className='admin-nav-link' to='/artists/admin/support-inbox'>
                      Support
                    </Link>
                  </Badge>
                  <Badge badgeContent={allUnreadChats?.length}  color="error">
                    <Link className='admin-nav-link' to='/artists/admin/all-inbox'>
                      All Chats
                    </Link>
                  </Badge>
                  <Link className='admin-nav-link' to='/artists/admin/system'>
                    System
                  </Link>
                </div>

                <Outlet />
    </div>
  )
}
