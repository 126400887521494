import { createContext, useContext, useEffect, useState } from 'react'
import axios from 'axios';
import { useAuth } from './AuthContext';

const InboxContext = createContext("");

export function UseInbox() {
    return useContext(InboxContext)
}

export function InboxProvider({children}) {

    const {currentUser, role, admin} = useAuth()

    const [unreadChats, setUnreadChats] = useState([])
    const [unreadSupportChats, setUnreadSupportChats] = useState([])
    const [allUnreadChats, setAllUnreadChats] = useState([])

    useEffect(() => {
        if (currentUser) {
            getUnreadChats()
        } else {
            // handle case when user logs out or currentUser becomes null
            setUnreadChats([]);
            setUnreadSupportChats([]);
            setAllUnreadChats([]);
        }
    }, [currentUser]);

    const getUnreadChats = () => {
        let chatEndpoint;
        if (role === 'client') {
            chatEndpoint = 'get-unread-client-chats';
        } else if (role === 'artist') {
            chatEndpoint = 'get-unread-artist-chats';
        } else {
            // TBD need to accommodate unregistered user since they also have inbox for support
            // However they have no firebase user or role so currently they don't call this
            // which means they have no inbox badge. Arguably this is not necessary for them
        }

        axios.post(`${process.env.REACT_APP_API}/chats/${chatEndpoint}`, {id: currentUser.uid})
        .then(function(res) {
            setUnreadChats(res.data)
        })
        .catch((error) => {
            console.log("Error getting unread chats", error)
        })

        if (admin) {
            axios.post(`${process.env.REACT_APP_API}/chats/get-unread-support-chats`, {id: currentUser.uid})
            .then(function(res) {
                setUnreadSupportChats(res.data)
            })
            .catch((error) => {
                console.log("Error getting unread support chats", error)
            })

            axios.get(`${process.env.REACT_APP_API}/chats/get-all-unread-chats`)
            .then(function(res) {
                setAllUnreadChats(res.data)
            })
            .catch((error) => {
                console.log("Error getting all unread chats", error)
            })
        }
    }

    const value = {
        unreadChats, getUnreadChats,
        unreadSupportChats,
        allUnreadChats,
    }

    return (
        <InboxContext.Provider value={value}>
            {children}
        </InboxContext.Provider>
    )
}
