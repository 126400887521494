import React, { useState, useEffect } from 'react'
import './DashboardSchedule.css'
import axios from 'axios'
import { useAuth } from '../../Context/AuthContext';
import { WeddingSchedule } from '../../Constants';
import eye from '../../Assets/Icons/eye.png'
import eyeClosed from '../../Assets/Icons/eye-closed.png'
import AddToScheduleModal from './Modals/AddToScheduleModal';
import EditScheduleModal from './Modals/EditScheduleModal';

export default function DashboardSchedule({ plannerOpen, setPlannerOpen }) {

    const [schedule, setSchedule] = useState("")
    const [open, setOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    const [dataToEdit, setDataToEdit] = useState("")
    const [indexToEdit, setIndexToEdit] = useState("")

    let { currentUser } = useAuth()

    useEffect(() => {
        getSchedule()
    }, [])

    // Helper function to convert time to minutes since midnight
    function timeToMinutes(time) {
        const [hours, minutes] = time.split(":").map(Number);
        return hours * 60 + minutes;
    }

    // Function to sort events by startTime
    function sortEvents(events) {
        return events.sort((a, b) => {
            const timeA = timeToMinutes(a.startTime);
            const timeB = timeToMinutes(b.startTime);

            // Custom sorting logic: if the time is before 05:00, add 1440 minutes (24 hours) to push it to the end. This is to ensure any weddings going past midnight are accounted for
            const adjustedTimeA = timeA < 300 ? timeA + 1440 : timeA;
            const adjustedTimeB = timeB < 300 ? timeB + 1440 : timeB;

            return adjustedTimeA - adjustedTimeB;
        });
    }

    const getSchedule = () => {
        axios.post(`${process.env.REACT_APP_API}/bookings/get-schedule-by-client-id`, {id: currentUser.uid})
        .then((res) => {
            if(!res.data){
                setSchedule(null)
            } else {
                // Sort the events before setting the schedule
                const sortedEvents = sortEvents(res.data.events);

                // Set the schedule with sorted events
                setSchedule({...res.data, events: sortedEvents});
            }

        })
        .catch((e) => console.log(e))
    }

    const handleEdit = (data, i) => {
        setEdit(true)
        setDataToEdit(data)
        setIndexToEdit(i)

    }

  return (
    <div id='dashboard-schedule' className={plannerOpen ? "planner-open" : ""}>
            {open &&
                <AddToScheduleModal
                    setOpen={setOpen}
                    getSchedule={getSchedule}
                    scheduleId={schedule?._id}
                    events={schedule?.events}
                />
            }

            {edit &&
                <EditScheduleModal
                    setOpen={setEdit}
                    data={dataToEdit}
                    index={indexToEdit}
                    events={schedule.events}
                    scheduleId={schedule._id}
                    getSchedule={getSchedule}
                />
            }

            <button onClick={() => setPlannerOpen(false)} id='close-planner-btn'>
                close
            </button>

            <h3>Wedding Day Planner</h3>
            <p style={{margin: '0 0 20px 30px', fontWeight: 500}}>Organise your entire day</p>
                    {schedule?.events?.map((item, i) => {
                        let lastIndex = schedule.events?.length -1
                        let eventInfo = WeddingSchedule.find(event => event.id == item.eventId)
                        return (
                            <div key={i} style={{display:'flex', justifyContent:'space-evenly', alignItems:'center', height: 120}}>
                                <div className='timeline-container'>
                                    <div className={i === 0 ? "timeline-no-line" : 'timeline-line'}/>
                                    <div className='timeline-dot'/>
                                    <div className={i === lastIndex ? "timeline-no-line" : 'timeline-line'}/>
                                </div>

                                <div key={i} className='schedule-item-container' onClick={() => handleEdit(item, i)}>
                                    <img style={{height: 55, marginRight: 20}} src={eventInfo?.icon}/>
                                    <div style={{display: 'flex', flexDirection:'column', width: '60%'}}>
                                        <p style={{fontWeight: 700, textAlign:'left', margin: 0}}>{item.startTime} {item.endTime && `- ${item.endTime}`}</p>
                                        <p style={{fontWeight: 700, textAlign:'left', margin: '5px 0'}}>{eventInfo?.name}</p>
                                        <p
                                            style={{fontWeight: 400, textAlign:'left', margin: 0, fontSize: 12, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '60%'}}
                                        >
                                                {item.notes? item.notes : "No notes"}
                                        </p>
                                    </div>
                                    <img style={{width: 40}} src={item.visible ? eye : eyeClosed}/>
                                    <div className={!item.visible ? 'disabled-overlay' :""}/>
                                </div>
                            </div>
                        )
                    })}
        <button id='add-to-schedule-btn' onClick={() => setOpen(true)}>Add Event</button>
    </div>
  )
}
