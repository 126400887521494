import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import moment from 'moment'
import { useAuth } from '../../Context/AuthContext';
import { Link } from 'react-router-dom';
import './Wedding.css'
import DashboardSchedule from './DashboardSchedule';
import { UseInbox } from '../../Context/InboxContext';
import { LogToServer } from '../../Utilities'
// Icons
import letter from '../../Assets/Icons/letter.png'
import quote from '../../Assets/Icons/dash-quote.png'
import discoBall from '../../Assets/Icons/disco-ball.png'
import hearts from '../../Assets/dash-hearts.png'
import calendar from '../../Assets/Icons/dash-calendar.png'

export default function Wedding() {

    let { currentUser } = useAuth()
    let { unreadChats } = UseInbox()

    let clientName = currentUser.displayName;
    const [requests, setRequests] = useState("")
    const [bookings, setBookings] = useState([])
    const [acts, setActs] = useState([])
    const [quotes, setQuotes] = useState([])
    const [plannerOpen, setPlannerOpen] = useState(false);

    useEffect(() => {
        LogToServer(`${clientName} is viewing their dashboard`)
        getRequests()
        getActs()
        getBookings()
    }, [])

    const getActs = () => {
        axios.get(`${process.env.REACT_APP_API}/acts/get-acts`)
        .then((res) => {
            setActs(res.data)
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const getRequests = () => {
        axios.post(`${process.env.REACT_APP_API}/requests/get-requests-with-quotes-by-client-id`, {id: currentUser.uid})
        .then((res) => {
            setRequests(res.data)
            const flattenedQuotes = res.data.flatMap(request => request.quotes)

            // Sort for latest first
            let sorted = flattenedQuotes.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
            setQuotes(sorted)
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const getBookings = () => {
        axios.post(`${process.env.REACT_APP_API}/bookings/get-client-bookings-by-id`, {id: currentUser.uid})
        .then((res)=>{
            setBookings(res.data)
        })
        .catch((error) => {
            console.log(error)
        })
    }

    return (
        <div id='wedding-dash'>
            <div id='wedding-dash-left'>

                <div id='schedule-tab' onClick={() => setPlannerOpen(true)}>
                    <p style={{margin:0}}>Wedding Planner</p>
                </div>

                <h1 style={{fontWeight: 900, color: '#313131', margin: 0, marginBottom: 10}}>Dashboard</h1>
                <p style={{ margin: 0, marginBottom: 20}}>Manage all your wedding entertainment in one place</p>

                <div id='countdown-container'>
                    <h2 style={{fontSize: 32, margin: 0}}>Hello {clientName.split(" ")[0]},</h2>
                    <div className='countdown-info'>
                        <div className='countdown-info'>
                            <p id='days-to-go'>{moment(requests[0]?.date).diff(moment(), 'days') + 1}</p>
                            <p id='countdown-txt'>days to your big day!</p>
                            <p id='countdown-txt-mobile'>days to go!</p>

                        </div>

                        <img id='dash-calendar-icon' src={calendar} alt='First Dance Music Wedding Icon'/>

                    </div>

                </div>
                <div id='wedding-dash-containers'>

                    <Link to={'quotes'} className='wedding-dash-container'>
                        <img className='wedding-dash-img' src={quote} alt='First Dance Music Wedding Icon'/>
                        <p style={{marginRight: 7}}>{quotes.length} Active</p>
                        <p>Quotes</p>
                    </Link>

                    <div className='wedding-dash-container'>
                        <img className='wedding-dash-img' src={discoBall} alt='First Dance Music Wedding Icon'/>
                        <p style={{marginRight: 7}}>{bookings.length} Confirmed</p>
                        <p>{bookings.length === 1  ? "Booking" : "Bookings"}</p>
                    </div>

                    <Link to={'inbox'} className='wedding-dash-container'>
                        <img className='wedding-dash-img' src={letter} alt='First Dance Music Wedding Icon'/>
                        <p style={{marginRight: 7}}>{unreadChats.length} New</p>
                        <p>Messages</p>
                    </Link>
                </div>

                <div>
                    <h3 style={{fontFamily:"Mont"}}>Latest Quotes</h3>

                    {quotes.slice(0, 10).map((quote, i) => {

                        let requestData = requests.find(request => request._id === quote.requestId)
                        let actData = acts.find(act => act._id === quote.selectedActId)
                        return (
                            <Link to={`quote/${quote._id}`} key={i} style={{textDecoration: 'none', color: '#313131', margin: 0}}>
                                <div className='quote-dash-container'>
                                    <div className='quote-highlight'/>
                                    <div className='quote-top-line'>
                                        <p>{actData?.actName}</p>
                                        <p>£{quote.customerGross}</p>
                                    </div>
                                    <p
                                        style={{margin: '5px 0', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '95%'}}
                                    >
                                        {requestData.info.actType} - {requestData.notes ? requestData.notes : "No notes added"}
                                    </p>
                                    <p>{requestData.info.startTime} - {requestData.info.endTime}</p>
                                </div>

                            </Link>
                        )
                    })}
                     <Link to={'quotes'} style={{fontWeight: 700, color: '#ecbdba'}}>
                    View All
                    </Link>
                </div>

               <img id='dash-hearts' src={hearts}/>
            </div>

            <DashboardSchedule plannerOpen={plannerOpen} setPlannerOpen={setPlannerOpen}/>

        </div>
    )
}
