
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import close from '../../../Assets/Icons/close.png'
import { signInWithEmailAndPassword, sendPasswordResetEmail} from "firebase/auth";
import { auth } from '../../../Firebase'
import { useAuth } from '../../../Context/AuthContext';
import { LogToServer } from '../../../Utilities'
import './Modal.css'

export default function ModalLogin({setOpen, setOpenSignup}) {

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)

  const { role } = useAuth()

  let navigate = useNavigate()


  useEffect(() => {
    if (role) {
      setOpen(false);
      if (role === 'client') {
        navigate('/clients')
      } else if (role === 'artist') {
        navigate('/artists')
      }
    }
  }, [role, navigate, setOpen])

  const togglePasswordVisibility = () => {
    setShowPassword(prevState => !prevState)
  }

  const handleContainerClick = (e) => {
    e.stopPropagation(); // Prevents click event from bubbling up to the modal div
  };

  const handleLogin = () => {
    if(!email || !password){
      toast.error("Please add email and password")
    } else {
      setLoading(true)
      signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        // Signed in
        setLoading(false)
        LogToServer(`User ${email} has logged in`)
      })
      .catch((error) => {
        setLoading(false)
        const errorCode = error.code;
        const errorMessage = error.message;
        toast.error(errorMessage)
        LogToServer(`Error logging in for user ${email}. Error code ${errorCode}`, errorMessage)
      });
    }
  }

  const handleSwitch = () => {
    setOpen(false)
    setOpenSignup(true)
  }

  const handleReset = () => {
    if (!email) {
      toast.error("Please input email")
    } else {
      sendPasswordResetEmail(auth, email)
      .then(() => {
        toast.success("Password reset email sent!")
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        toast.error(errorMessage)
      });
    }
  }

  return (
    <div className='modal' onClick={(e) => setOpen(false)}>

        <div className='modal-container' onClick={handleContainerClick}>
          <img className='close-modal-btn hover' src={close} alt='Close First Dance Music login box' onClick={() => setOpen(false)}/>

          <h3 className='modal-header' style={{color: '#313131'}}>Login</h3>

          <input
            className='top-input'
            type='email'
            placeholder='Email address'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <input
            className='bottom-input'
            placeholder='Password'
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <label style={{ paddingTop: '5px', fontSize: '12px', color: 'black' }}>
            <input
              type="checkbox"
              onChange={togglePasswordVisibility}
              checked={showPassword}
            />
            Show Password
          </label>

          <button className='modal-btn' onClick={handleLogin} disabled={loading}>
            {loading ? 'Logging in...' : "Login"}
          </button>

          <button id='forgot-btn' onClick={handleReset}>Forgotten password?</button>

          {/*<p>Don't have an account? <span className='hover' style={{textDecoration: 'underline'}} onClick={handleSwitch}>Create one for free</span></p>*/}

        </div>
    </div>
  )
}
