import React, { useEffect, useState} from 'react'
import { useAuth } from '../../../Context/AuthContext'
import axios from 'axios'
import Avatar from '@mui/material/Avatar';
import moment from 'moment'
import '../../CommonInbox.css'
import { useNavigate } from 'react-router-dom';

export default function SupportInbox() {
  let navigate = useNavigate()
  let { currentUser } = useAuth()
  const [chats, setChats] = useState([])

  useEffect(() => {
    getChats()
  },[])

  const getChats = () => {
    axios.get(`${process.env.REACT_APP_API}/chats/get-admin-chats`)
      .then((res) => {
        setChats(res.data)
      })
      .catch((error) => console.log(error))
  }

  return (
    <div id='support-inbox-page' >
      <div id='inbox-container'>
        {chats.length < 1 ? (
          <p style={{ textAlign: 'center' }}>Inbox empty</p>
        ) : (
          chats
            .slice() // Create a shallow copy of the array to avoid mutating the original
            .sort(function(a, b) {
              return new Date(b.lastMsgAdded) - new Date(a.lastMsgAdded);
            })
            .map((chat, i) => {
              return (
                <div key={i} className='inbox-row' onClick={() => navigate(`/artists/admin/support-inbox/${chat._id}`)}>
                  <div style={{ display: 'flex' }}>
                    <Avatar
                      sx={{ width: 50, height: 50, mr: 3 }}
                    >
                      {chat.clientName ? chat.clientName?.charAt(0) : chat.artistName?.charAt(0)}
                    </Avatar>
                    <p
                      className='inbox-name'
                      style={{ fontWeight: chat.adminRead ? 'normal' : 'bold' }}
                    >
                      {chat.clientName || chat.artistName}
                    </p>
                  </div>
                  <p style={{ textAlign: 'right' }}>{moment(chat.lastMsgAdded).fromNow()}</p>
                </div>
              );
            })
        )}
      </div>
    </div>
  )
}
