import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useAuth } from '../../Context/AuthContext'
import moment from 'moment'
import './Wallet.css'
import { Link } from 'react-router-dom'
import WithdrawFundsModal from './Modals/WithdrawFundsModal'

  //  -- Wallet --
  // Display what money is owed to an artist and what has been paid

  // Considerations:
  //  - paid (and fulfilled) indicate client has directly paid or has set up a payment plan NOT that the artist has been paid
  //  - need a flag and a method to mark the artist as having been paid and when.
  //    What is the process for payment? Manual by an account admin?
  //  - how to identify that the event was even honoured by the original artist? how to substitute?
  //  - is there any point in a wallet schema? something that gets updated with bookings and then payout info?
  //    or is dynamic retrieve as in the initial method better and avoids duplication and scattering the update of that info


export default function Wallet() {

    const [walletContent, setWalletContent] = useState([]);
    const [totalPaid, setTotalPaid] = useState(0);
    const [totalPending, setTotalPending] = useState(0);
    const [activeTab, setActiveTab] = useState(0);
    const [open, setOpen] = useState(false);

    let { currentUser } = useAuth()

    useEffect(() => {
        getWalletContent()
    }, [])

    const getWalletContent = () => {
        axios.post(`${process.env.REACT_APP_API}/users/get-wallet-content-by-id`, {
            artistId: currentUser.uid
        })
        .then((res) => {
            const sortedContent = res.data.sort((a, b) => new Date(a.weddingDate) - new Date(b.weddingDate))
            calculateTotals(sortedContent)
            setWalletContent(sortedContent)
        })
        .catch((error) => console.log(error))
    }

    const calculateTotals = (content) => {
        let paidTotal = 0
        let pendingTotal = 0
        const today = new Date()

        content.forEach(item => {
            const paymentDueDate = new Date(item.weddingDate)
            paymentDueDate.setDate(paymentDueDate.getDate() + 5)

            const amount = parseFloat(item.totalFees)
            if (paymentDueDate < today) {
                paidTotal += amount;
            } else {
                pendingTotal += amount
            }
        });

        setTotalPaid(paidTotal.toFixed(2))
        setTotalPending(pendingTotal.toFixed(2))
    }


    return (
        <div className='business-outlet'>

            `{open && <WithdrawFundsModal setOpen={setOpen}/>}

            <div style={{display:'flex'}}>
                <div id='current-balance'>
                    <p>Current balance</p>
                    <h2>£{((totalPaid)*1 + (totalPending)*1).toFixed(2)}</h2>
                </div>
                <div  id='available-balance'>
                    <p>Available funds</p>
                    <h2>£{totalPaid}</h2>
                </div>
            </div>

            <button 
                style={{marginTop: 20}} 
                className={totalPaid > 0 ? 'primary-btn' : 'disabled-btn'}    
                onClick={() => setOpen(true)}
                disabled={(totalPaid * 1) > 0 ? false : true}

            >
                    Withdraw Funds        
            </button>

            <div id='transaction-list'>
                <div style={{display:'flex', overflow: 'scroll'}}>
                    <div className='wallet-tab-container hover' onClick={() => setActiveTab(0)}>
                        <p>All transactions</p>
                        <div className={activeTab === 0 ? 'wallet-tab-underline' : ''}/>
                    </div>
                    <div className='wallet-tab-container hover' onClick={() => setActiveTab(1)}>
                        <p>In</p>
                        <div className={activeTab === 1 ? 'wallet-tab-underline' : ''}/>
                    </div>
                    <div className='wallet-tab-container hover' onClick={() => setActiveTab(2)}>
                        <p>Out</p>
                        <div className={activeTab === 2 ? 'wallet-tab-underline' : ''}/>
                    </div>
                </div>
            </div>

            {walletContent.length === 0 ? (
                <div id='empty-wallet-container'>
                    <p>No transactions available</p>
                </div>
            ) : (
                <div>
                    <table className='wallet-table'>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Client</th>
                                <th>Act</th>
                                <th>Amount</th>
                                <th>Withdrawal date <span style={{color:'grey', fontSize: 14}}>(estimated)</span></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {walletContent.map((item, index) => {
                                const paymentDueDate = new Date(item.weddingDate);
                                paymentDueDate.setDate(paymentDueDate.getDate() + 5);

                                const status = paymentDueDate < new Date() ? 'Paid' : 'Pending';
                                const amount = parseFloat(item.totalFees).toFixed(2);
                                const vatApplied = parseFloat(item.vatRate) > 1 ? 'Yes' : 'No';

                                return (
                                    <tr key={index}>
                                        <td>{moment(item.weddingDate).format('DD MMM YYYY')}</td>
                                        <td>{item.clientName}</td>
                                        <td>{item.actName || 'Unknown Act'}</td>
                                        <td style={{color: 'green', fontWeight: 'bold'}}>+ £{amount}</td>
                                        <td style={{color: 'grey'}}>{moment(item.weddingDate).add(5, 'days').format('DD MMM YYYY')}</td>
                                        <td >
                                            <Link to={`/artists/invoices/insert-id-to-query`} className='invoice-btn'>View Invoice</Link>
                                        </td> 
                                        {/* Invoice generation to be handled. Auto create on booking success and stored as pdf in Firebase storage */}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                   
                </div>
            )}
        </div>
    )
}
