import React, { useState } from 'react'
import './Invoice.css'
import logo from '../../Assets/logo-stacked-pink.png'
import moment from 'moment'

export default function Invoice() {

    const [addressTo, setAddressTo] =useState("")
    const [addressFrom, setAddressFrom] =useState("")
    const [invoiceNo, setInvoiceNo] =useState("")

  return (
    <div className='business-outlet'>
        <div id='invoice'>
            <div style={{display: 'flex', justifyContent:'space-between'}}>
                <img className='invoice-logo' src={logo} alt='First Dance Music Logo'/>
                <div id='invoice-details'>
                    <h1>Invoice</h1>
                    <h4>Invoice No. <span style={{fontWeight: 300}}>FDM-01052024</span></h4>
                    <h4>Invoice date: <span style={{fontWeight: 300}}>{moment().format('DD MMM YYYY')}</span></h4>
                </div>
            </div>

            <div style={{display: 'flex', justifyContent:'space-between'}}>
                <div id='address-to-container'>
                    <h4>Bill to</h4>
                    <p>Client name</p>
                    <p>Venue details</p>
                </div>                
                <div id='address-from-container'>
                    <h4>Bill from</h4>

                    {!addressFrom? 
                    <button>Add Address</button>
                    :
                    addressFrom.map((line, i) => {
                        return (
                            <p key={i}>{line}</p>
                        )
                    })
                    }
                </div>
            </div>

            <table id='invoice-table'>
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Quantity</th>
                        <th>Unit Price</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Live Music</td>
                        <td>1</td>
                        <td>£300</td>
                        <td>£300</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td></td>
                        <td></td>
                        <td>Subtotal</td>
                        <td>£300</td>

                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td className='table-underline'>VAT (0%)</td>
                        <td className='table-underline'>£0</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td>TOTAL</td>
                        <td>£300</td>
                    </tr>
                </tfoot>
            </table>

            <div>
                <h4>Payment information</h4>
                <h5>Account Name: <span style={{fontWeight: 300}}>Account name</span> </h5>
                <h5>Account No: <span style={{fontWeight: 300}}>00000000</span></h5>
                <h5>Sort Code: <span style={{fontWeight: 300}}>00-00-00</span></h5>
            </div>

        </div>
    </div>
  )
}
