import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useAuth } from '../Context/AuthContext'
import chatIcon from '../Assets/Icons/customer-service.png'
import './ContactSupport.css'
import { useLocation } from 'react-router-dom'

const ContactSupport = ({ children }) => {
    let { currentUser, role } = useAuth()

    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [message, setMessage] = useState('')
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [messageSent, setMessageSent] = useState(false)
    const location = useLocation();

    // Check if the pathname starts with /clients/inbox
    const isClientsInbox = location.pathname.startsWith('/clients/inbox');
    const isArtistsInbox = location.pathname.startsWith('/artists/inbox');

    useEffect(() => {
        if (currentUser) {
            setName(currentUser.displayName);
            setEmail(currentUser.email);
        }
    }, [currentUser]);

    const handleEmailChange = (event) => {
        setEmail(event.target.value)
    }

    const handleNameChange = (event) => {
        setName(event.target.value)
    }

    const handleIconClick = () => {
        setIsDialogOpen(!isDialogOpen)
        if (!isDialogOpen) {
            setMessageSent(false)
        }
    }

    const handleCloseDialog = () => {
        setIsDialogOpen(false)
        if (!isDialogOpen) {
            setMessageSent(false)
        }
    }

    function capitalizeFirstLetter(string) {
        return string?.charAt(0).toUpperCase() + string?.slice(1);
    }

    const handleSendMessage = () => {
        setMessageSent(true)

        // If a user is not logged in there will be
        // no currentUser so email must be entered
        // id will be generated backend
        let payload = {
            senderId: currentUser ? currentUser.uid : '',
            senderName: name,
            senderEmail: email,
            sentBy: capitalizeFirstLetter(role) || 'Unregistered',
            message
        }

        axios.post(`${process.env.REACT_APP_API}/chats/open-support-chat`, payload)
        .then(() => {
            // clear the fields after message sent
            setMessage('')
            if (!currentUser) {
                setEmail('')
                setName('')
            }
        })
        .catch((error) => {
            setMessageSent(false)
            console.log(error)
        })
    };

    return (
        <div className="contactsupport-container">
            <div className={(isClientsInbox || isArtistsInbox) ? "support-hidden" : "icon-container"}>
                <button className="icon-btn" onClick={handleIconClick}>
                    <img src={chatIcon} alt="Icon" />
                </button>
            </div>

            {isDialogOpen && (
                <div className="dialog-box">
                    <div className="dialog-box">
                        <div className="dialog-content">
                            <span className="dialog-title" onClick={handleCloseDialog}>How Can We Help?</span>
                            <span className="close-btn" onClick={handleCloseDialog}>&times;</span>

                            <div className="dialog-text">
                                Online chat is not yet available but send<br />
                                us a message and we'll get back to you.
                            </div>

                            <input
                                type="text"
                                value={name}
                                onChange={handleNameChange}
                                placeholder="Full name"
                                className="name-input"
                                readOnly={!!currentUser?.displayName}
                            />

                            <input
                                type="email"
                                value={email}
                                onChange={handleEmailChange}
                                placeholder="Enter your email"
                                className="email-input"
                                readOnly={!!currentUser?.email}
                            />

                            <div>
                                <textarea
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    placeholder="Enter your message"
                                    className="message-input"
                                />
                            </div>

                            {messageSent ?
                                <div className="dialog-text">
                                    Your message has been sent.<br />
                                </div>
                            :
                                <button
                                    style={{
                                        backgroundColor: !message.trim() ? '#ccc' : "#ecbdba",
                                        color: !message.trim() ? null : "#fff",
                                        cursor: !message.trim() ? 'not-allowed' : null,
                                        opacity: !message.trim() ? 0.6 : null,
                                        borderRadius: 4,
                                        border: 'unset',
                                        padding: '5px 10px',
                                        fontWeight: 600
                                    }}
                                    title={!message.trim() ? 'Type a message' : ''}
                                    onClick={handleSendMessage}
                                    disabled={!(!!message?.trim() && !!name?.trim() && !!email?.trim()) || messageSent}
                                >
                                    Send
                                </button>
                            }
                            <p style={{ fontSize: '10px', fontWeight: 'bold' }}>
                                Please do not include sensitive data in your message.
                            </p>
                        </div>
                    </div>
                </div>
            )}
            {children}
        </div>
    );
};

export default ContactSupport
