import React, { useState, useEffect} from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import './MusicianUI.css'
import {  signOut } from "firebase/auth";
import { auth } from '../../Firebase';
import logo from '../../Assets/logo-stacked-pink.png'
import Badge from '@mui/material/Badge';
import { UseInbox } from '../../Context/InboxContext';
import { useAuth } from '../../Context/AuthContext';
import mobileLogo from '../../Assets/pinkLogo.webp'

export default function MusicianUI() {
  let location = useLocation();

  const [navOpen, setNavOpen] = useState(window.screen.width > 750 ? true : false)
  const [active, setActive] = useState('Dashboard')

  let { admin } = useAuth()
  let { unreadSupportChats } = UseInbox()


  useEffect(() => {
    let url = location.pathname

    if(url === '/artists'){
      setActive("Dashboard")
    } else if(url.startsWith('/artists/acts')){
      setActive("Acts")
    } else if(url.startsWith('/artists/weddings') || url.startsWith('/artists/requests')){
      setActive("Weddings")
    } else if(url.startsWith('/artists/wallet')){
      setActive("Wallet")
    } else if(url.startsWith('/artists/inbox')){
      setActive("Inbox")
    } else if(url.startsWith('/artists/admin')){
      setActive("Admin")
    } else {
      setActive("")
    }

  }, [location])

    const handleLogout = () =>{
        signOut(auth).then(() => {
          // Sign-out successful.
        }).catch((error) => {
          console.log("Error handling logout", error)
        });
      }



  return (
    <div>

      <div className='mobile-header'>
        <div className={navOpen ? " nav-icon-1 open-burger" : "nav-icon-1"} onClick={() => setNavOpen(!navOpen)}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>

        <Link to={'/'}>
            <img id='mobile-logo' src={mobileLogo}/>
        </Link>
      </div>

      



        <header className={navOpen ? 'artist-nav artist-nav-open' : 'artist-nav'}>

          <div >

            <Link to={'/'}>
              <img id='sidenav-logo' src={logo} style={{width: '60%', marginLeft: '20%', marginBottom: 30, marginTop: 30}}/>
            </Link>

            <nav>
              <p style={{fontFamily: 'Satoshi', fontSize: 11, color:'#111111', opacity: 0.5}}>MENU</p>
                <Link to={'/artists'} className={active === 'Dashboard' ? 'business-nav-item-active' : 'business-nav-item'} onClick={() => window.screen.width < 750 ? setNavOpen(false) : null}>
                  <img className={active === 'Dashboard' ? 'nav-icon icon-filter' : 'nav-icon'} src={require('../../Assets/Icons/home.png')} alt='Home icon'/>
                  <p className={active === 'Dashboard' ? 'business-link-txt-active' : 'business-link-txt'}>Dashboard</p>
                </Link>

                <Link to={'weddings'} className={active === 'Weddings' ? 'business-nav-item-active' : 'business-nav-item'} onClick={() => window.screen.width < 750 ? setNavOpen(false) : null}>
                  <img className={active === 'Weddings' ? 'nav-icon icon-filter' : 'nav-icon'} src={require('../../Assets/Icons/booking.png')} alt='Bookings icon'/>
                  <p className={active === 'Weddings' ? 'business-link-txt-active' : 'business-link-txt'}>Weddings</p>
                </Link>
                <Link to={'acts'} className={active === 'Acts' ? 'business-nav-item-active' : 'business-nav-item'}onClick={() => window.screen.width < 750 ? setNavOpen(false) : null} >
                  <img className={active === 'Acts' ? 'nav-icon icon-filter' : 'nav-icon'} src={require('../../Assets/Icons/acts.png')} alt='Acts icon'/>
                  <p className={active === 'Acts' ? 'business-link-txt-active' : 'business-link-txt'}>Act Manager</p>
                </Link>
                <Link to={'wallet'} className={active === 'Wallet' ? 'business-nav-item-active' : 'business-nav-item'} onClick={() => window.screen.width < 750 ? setNavOpen(false) : null}>
                  <img className={active === 'Wallet' ? 'nav-icon icon-filter' : 'nav-icon'} src={require('../../Assets/Icons/wallet.png')} alt='Wallet icon'/>
                  <p className={active === 'Wallet' ? 'business-link-txt-active' : 'business-link-txt'}>Wallet</p>
                </Link>
                <Link  to={'inbox'} className={active === 'Inbox' ? 'business-nav-item-active' : 'business-nav-item'} onClick={() => window.screen.width < 750 ? setNavOpen(false) : null}>
                  <img className={active === 'Inbox' ? 'nav-icon icon-filter' : 'nav-icon'} src={require('../../Assets/Icons/letter.png')} alt='Messages icon'/>
                  <p className={active === 'Inbox' ? 'business-link-txt-active' : 'business-link-txt'}>Inbox</p>
                </Link>

                {admin && (
                      <Link  to={'admin'} className={active === 'Admin' ? 'business-nav-item-active' : 'business-nav-item'} onClick={() => window.screen.width < 750 ? setNavOpen(false) : null}>
                        <img className={active === 'Admin' ? 'nav-icon icon-filter' : 'nav-icon'} src={require('../../Assets/Icons/admin.png')} alt='Admin icon'/>
                        <Badge badgeContent={unreadSupportChats?.length}  color="error">
                          <p className={active === 'Admin' ? 'business-link-txt-active' : 'business-link-txt'}>Admin</p>
                        </Badge>

                      </Link>

                )}

            </nav>
            </div>

            <button className='business-nav-item' id='business-logout-btn' onClick={handleLogout}>
                  <img className='nav-icon' src={require('../../Assets/Icons/logout.png')} alt='Logout icon'/>
                  <p className='business-link-txt'>Logout</p>
                </button>

        </header>

        <Outlet context={{ active, setActive }}/>
    </div>
  )
}
