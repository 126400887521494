import React from 'react'
import Footer from '../Footer'
import './Terms.css'

export default function CommunityGuidelines() {
  return (
    <>
    <div style={{padding:'2rem', width: 1200, maxWidth: '90vw', margin: '0 auto'}}>
        <h2 style={{fontFamily:"Mont"}}>First Dance Music Community Guidelines</h2>

        <p>Welcome to First Dance Music! We are dedicated to helping brides find the perfect musicians for their wedding day. To ensure a positive and professional experience for everyone, we've established the following community guidelines. By using our platform, you agree to adhere to these guidelines.</p>

        <h3 style={{fontFamily:"Mont"}}>1. Respect and Professionalism</h3>
        <li className='indented'><b>Respectful Communication:</b> Always communicate with others in a respectful and courteous manner. Disrespectful, offensive, or abusive language will not be tolerated.</li>
        <li className='indented'><b>Professional Behavior:</b> Musicians and clients should maintain a professional demeanor in all interactions. Punctuality, clear communication, and reliability are key.</li>
        <h3 style={{fontFamily:"Mont"}}>2. Honesty and Transparency</h3>
        <li className='indented'><b>Accurate Profiles:</b> Musicians must provide accurate and up-to-date information on their profiles, including their skills, experience, and availability.</li>
        <li className='indented'><b>Clear Quotes:</b> All quotes provided should be clear, accurate, and inclusive of all costs. Hidden fees or misleading information are unacceptable.</li>
        <h3 style={{fontFamily:"Mont"}}>3. Booking and Payments</h3>
        <li className='indented'><b>Timely Responses:</b> Both clients and musicians are expected to respond to messages and booking requests in a timely manner.</li>
        <h3 style={{fontFamily:"Mont"}}>4. Conflict Resolution</h3>
        <li className='indented'><b>Addressing Issues:</b> If a problem arises, communicate directly and respectfully to resolve it. If necessary, use the platform’s dispute resolution tools.</li>
        <li className='indented'><b>Reporting Violations:</b> Report any violations of these guidelines to our support team. We are committed to maintaining a safe and respectful community.</li>
        <h3 style={{fontFamily:"Mont"}}>5. Privacy and Security</h3>
        <li className='indented'><b>Protect Personal Information:</b> Do not share personal information publicly on the platform. Use the internal messaging system to communicate securely.</li>
        <li className='indented'><b>Respect Privacy:</b> Do not share or misuse any personal information obtained through the platform.</li>
        <h3 style={{fontFamily:"Mont"}}>6. Content Guidelines</h3>
        <li className='indented'><b>Appropriate Content:</b> All content, including messages, profile information, and quotes, must be appropriate for a professional setting. Avoid any content that could be considered offensive or inappropriate.</li>
        <li className='indented'><b>Intellectual Property:</b> Respect intellectual property rights. Only share content that you own or have permission to use.</li>
        <h3 style={{fontFamily:"Mont"}}>7. Platform Usage</h3>
        <li className='indented'><b>Proper Use:</b> Use the platform only for its intended purpose: connecting brides with musicians for weddings. Any misuse of the platform, including spamming or harassment, will result in disciplinary action.</li>
        <li className='indented'><b>Feedback:</b> Provide constructive feedback and reviews. Honest and respectful feedback helps improve the community and the service.</li>
        <h3 style={{fontFamily:"Mont"}}>8. Compliance with Laws</h3>
        <li className='indented'><b>Legal Compliance:</b> Ensure all activities on the platform comply with British law. Illegal activities are strictly prohibited.</li>

        <p>By following these guidelines, you help create a positive, professional, and supportive community on First Dance Music. We appreciate your cooperation and commitment to maintaining these standards.</p>

        <p>For any questions or support, please contact our team at info@firstdancemusic.co.uk</p>

        <p>Thank you,</p>
        <p>The First Dance Music Team</p>
    </div>
    <Footer />
    </>

  )
}
