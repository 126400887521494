import React, { useState, useRef, useEffect } from 'react'
import { useAuth } from '../Context/AuthContext'
import logout from '../Assets/Icons/logout.png'
import {  signOut } from "firebase/auth";
import { auth } from '../Firebase'
import ModalLogin from './PublicUI/Modals/ModalLogin'
import ModalSignUp from './PublicUI/Modals/ModalSignUp'
import user from '../Assets/Icons/profile-user.png'
import userPink from '../Assets/Icons/pink-profile.png'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

export default function DropDownMenu() {

    let { currentUser, role } = useAuth()
    let location = useLocation();
    let navigate = useNavigate()

    const [showPopover, setShowPopover] = useState(false)
    const [openLogin, setOpenLogin] = useState(false)
    const [openSignup, setOpenSignup] = useState(false)

    const dropdownRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowPopover(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);


    const handleLogout = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
            window.location.reload()
        }).catch((error) => {
            console.log("Error handling logout", error)
        });
    }

    const handleLogin = () => {

        if (role === 'artist') {
            navigate('/artists')
        } else if (role === 'client'){
            // otherwise this means they are an artist since there are no other roles as yet
            navigate('/clients')
        } else {
            toast.error("Please log in to your First Dance Music Account")
        }
    }

  return (
    <div
        className='hover'
        ref={dropdownRef}
        style={{display:'flex', alignItems:'center', position: 'relative'}}
        onClick={() => setShowPopover(!showPopover)}
        >
                {openLogin && <ModalLogin setOpen={setOpenLogin} setOpenSignup={setOpenSignup}/>}
                {openSignup && <ModalSignUp setOpen={setOpenSignup} setOpenLogin={setOpenLogin}/>}

                <div id='dropdown-wrapper' style={location.pathname === '/' ? {borderColor: '#ecbdba'} : {}}>
                    <div id='burger-container'>
                        <div className='burger-line' style={location.pathname === '/' ? {backgroundColor: '#ecbdba'} : {}}/>
                        <div className='burger-line' style={location.pathname === '/' ? {backgroundColor: '#ecbdba'} : {}}/>
                        <div className='burger-line' style={location.pathname === '/' ? {backgroundColor: '#ecbdba'} : {}}/>
                    </div>
                    <img style={{width: 30, height: 30}} src={location.pathname === '/' ? userPink : user} alt='First dance music booking user icon'/>

                </div>

                {showPopover &&
                <div className='header-popover'>
                    {currentUser ?
                    <>
                        <Link to={'/find/artists'} className='hover-with-background popover-item mobile-menu-item'>
                            Find Artists
                        </Link>
                        <Link to={'/find'} className='hover-with-background popover-item mobile-menu-item'>
                            Post Wedding
                        </Link>
                        <div className='divider mobile-menu-item'/>

                        <p className='hover-with-background popover-item' onClick={handleLogin}>
                            My account
                        </p>
                        <div className='divider'/>

                        <div className='hover-with-background popover-item' style={{display:'flex', alignItems: 'center'}} onClick={handleLogout}>
                            <img style={{height: 20, width: 20, marginRight: 10}} src={logout} alt='First Dance Music logout button'/>
                            <p style={{margin: 0}}>Logout</p>
                        </div>
                    </>
                    :
                    <>
                        <Link to={'/find/artists'} className='hover-with-background popover-item mobile-menu-item'>
                            Find Artists
                        </Link>
                        <Link to={'/find'} className='hover-with-background popover-item mobile-menu-item'>
                            Post Wedding
                        </Link>
                        <div className='divider mobile-menu-item'/>

                        <p className='hover-with-background popover-item' onClick={() => setOpenSignup(true)}>Artist Sign Up</p>
                        <div className='divider'/>
                        <p className='hover-with-background popover-item' onClick={() => setOpenLogin(true)}>Login</p>
                    </>

                    }

                </div>
                }
        </div>
  )
}
