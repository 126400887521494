import React from 'react'
import './HowItWorks.css'
import step1 from '../../../Assets/step1.png'
import step2 from '../../../Assets/step2.png'
import step3 from '../../../Assets/step3.png'
import step4 from '../../../Assets/step4.png'
import rectangle from '../../../Assets/Rectangle.png'

export default function HowItWorksMobile() {
  return (
    <div id='how-it-works-mobile'>
        <h2 id='how-it-works-header'>How It Works</h2>
        <p style={{ fontWeight: 600, fontSize: 18, color: '#313131', marginBottom: 50}}>With First Dance Music, planning the music for your wedding is simple, stress-free, and enjoyable. Create unforgettable memories for your special day in just a few easy steps!</p>
        <div className='mobile-step'>
            <img style={{position: 'relative', left: '-2rem'}} className='mobile-step-img' src={step1}/>
            <div className='how-it-works-step'>
                <h2>01.</h2>
                <h3>Post your wedding date</h3>
                <p>Begin by posting your wedding date on our platform. This allows us to match you with available musicians for your special day.</p>
            </div>
        </div>
        <div className='mobile-step'>
            <div className='how-it-works-step'>
                <h2>02.</h2>
                <h3>Receive quotes from artists</h3>
                <p>Sit back and relax as you receive quotes from all the talented artists available on your chosen date. Our wide selection ensures you'll find the perfect match for your wedding vibe..</p>
            </div>
            <img style={{position: 'relative', right: '-2rem'}} className='mobile-step-img' src={step2}/>
        </div>
        <div className='mobile-step'>
            <img style={{position: 'relative', left: '-2rem'}} className='mobile-step-img' src={step3}/>
            <div className='how-it-works-step'>
                <h2>03.</h2>
                <h3>Pick the right artist for you</h3>
                <p>Visit our artists page to explore profiles and listen to samples from each musician, choose the artists whose style resonates with you. Check out our artists page here.</p>
            </div>
        </div>
        <div className='mobile-step'>
            <div className='how-it-works-step'>
                <h2>04.</h2>
                <h3>Secure your chosen musician</h3>
                <p>Once you've found the ideal musician, it's time to book! You have the flexibility to pay upfront or spread the cost over time* with no interest and no credit checks required!</p>
                <p style={{fontSize: 12}}>*Wedding must be more than 3 months in advance of booking date and payments must finish one month before the wedding date</p>
            </div>
            <img style={{position: 'relative', right: '-2rem'}} className='mobile-step-img' src={step4}/>
        </div>

        <img style={{position: 'relative', left: '-2rem'}} id='rectangle-mobile' src={rectangle}/>

    </div>
  )
}
