import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import { UseInbox } from '../../../Context/InboxContext'
import ModalManageTicket from './ModalManageTicket'
import '../../CommonChat.css'
import '../../PublicUI/Modals/Modal.css'

// admin support chat, i.e. for messages from admin to artist and client
export default function SupportChat() {

    let { id } = useParams()
    let { getUnreadChats } = UseInbox()

    const [messages, setMessages] = useState([])
    const [message, setMessage] = useState("")
    const [chat, setChat] = useState("")
    const [showModal, setShowModal] = useState(false)

    const messagesEndRef = useRef(null)

    const lastMessage = messages.length > 0 ? messages[messages.length - 1] : null;

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        scrollToBottom()
    }, [messages]);

    useEffect(() => {
        getMessages()
        getChat()
    },[])

    const getChat = () => {
        axios.post(`${process.env.REACT_APP_API}/chats/get-admin-chat-by-id`, {chatId: id})
        .then((res) => {
            setChat(res.data)
        })
        .catch((error) => console.log(error))
    }

    const getMessages = () => {
        axios.post(`${process.env.REACT_APP_API}/chats/get-messages-by-chat-id`, {chatId: id})
        .then((res) => {
            setMessages(res.data)
            getUnreadChats()
        })
        .catch((error) => console.log(error))
    }

    const sendMessage = () => {
        if (!message) {
            return
        } else{
            axios.post(`${process.env.REACT_APP_API}/chats/send-message-froma-adminsupport`, {
                chatId: id,
                message
            })
            .then((res) => {
                setMessage("")
                getMessages()
                console.log(res.data)
            })
            .catch((error)=>console.log(error))
        }
    }

    return (
        <div id='chat-page'>
            {showModal &&
                <ModalManageTicket
                    selectedChat={chat}
                    onClose={() => setShowModal(false)}
                    lastMessage={lastMessage}
                />
            }

            {messages.map((msg, i) => {
                let lines = msg.message.split('\n')

                return (
                    <div key={i} className={msg.sentBy === "AdminSupport" ? 'own-msg-container' : undefined}>
                        <div className={msg.sentBy !== "AdminSupport" ? 'other-msg' : 'own-msg'}>
                            {lines.map((line, index) => {
                                return (
                                    <p key={index}>
                                        <React.Fragment key={index}>
                                            {line} <br />
                                        </React.Fragment>
                                    </p>
                                )
                            })}

                            <p className='timestamp'>{moment(msg.timeStamp).format('HH:mm A, DD MMM')}
                                {chat.ticketId && (
                                    <>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <em title={`${chat.ticketId}`}>
                                            Support reference: {chat.ticketId.slice(-10)}
                                        </em>
                                    </>
                                )}
                            </p>
                        </div>

                        {(i === messages.length - 2) && (
                            <div ref={messagesEndRef}> </div>
                        )}
                    </div>
            )})}

            <div id='message-container'>
                <textarea id='msg-box' value={message} onChange={(e) => setMessage(e.target.value)} placeholder='Enter your message'/>
                <div className='button-container'>
                    <button className='send-btn' onClick={sendMessage}>Send support message</button>
                    <button className='send-btn' onClick={() => setShowModal(true)}>Update support ticket</button>
                </div>
            </div>

        </div>
    )
}
