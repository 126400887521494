import React, { useState, useEffect } from 'react'
import './DashboardCalendar.css'
import moment from 'moment'
import left from '../../Assets/Icons/left.png'
import right from '../../Assets/Icons/right.png'
import { Link } from 'react-router-dom'
import { ActTypes } from '../../Constants';
import discoBall from '../../Assets/Icons/disco-ball.png'

export default function DashboardCalendar({calendarOpen, setCalendarOpen, requests, bookings}) {

  const [month, setMonth] = useState(moment());
  const [events, setEvents] = useState([])

  useEffect(() => {
    getEventsByMonth()
  }, [month, requests, bookings])

  const getEventsByMonth = () => {
    const selectedYear = month.year();
    const selectedMonth = month.month(); // Note: month() is 0-based (January is 0)
    const filteredRequests = requests.filter(request =>
      moment(request.date).year() === selectedYear && moment(request.date).month() === selectedMonth
    );
    const filteredBookings = bookings.filter(booking =>
      moment(booking.date).year() === selectedYear && moment(booking.date).month() === selectedMonth
    );
    // Combine filtered results
    const combinedEvents = [...filteredRequests, ...filteredBookings];
    const sortedEvents = combinedEvents.sort((a, b) => moment(a.date).diff(moment(b.date)));
    setEvents(sortedEvents);

    console.log('Filtered and Sorted Events:', sortedEvents); 
  };

  return (
    <div id='dashboard-calendar' className={calendarOpen ? "planner-open" : ""}>
           
        <button onClick={() => setCalendarOpen(false)} id='close-planner-btn'>
          close
        </button>

        <div style={{width: '80%', display:'flex', justifyContent: 'space-evenly', alignItems:'center', marginLeft: '10%'}}>
          <img className='hover' style={{height: 40}} src={left} onClick={() => setMonth(month.clone().subtract(1, 'month'))}/>
          <h3 style={{width: 150, margin: 0, textAlign:'center'}}>{month.format('MMM YY')}</h3>
          <img className='hover' style={{height: 40}} src={right} onClick={() => setMonth(month.clone().add(1, 'month'))}/>
        </div>
           

        {events.length < 1 ?
          <p style={{marginTop: 100, width: '100%', textAlign:'center'}}>No events</p>
          : 
          events.map((event, i) => {
            
            let icon = ActTypes.find((act) => act.name == event.info?.actType )

            return event.eventType === "Request" ? (
              <Link to={`requests/${event._id}`} key={i} style={{textDecoration: 'none', color: '#313131'}}>
              <div className='quote-dash-container' style={{backgroundColor: 'white', margin: '20px auto', width: '90%', flexDirection: 'row', alignItems: 'center', justifyContent:'space-between'}}>
                  <div className='quote-highlight'/>
                  
                  <div style={{ textAlign: 'center', paddingRight: 15}}>
                    <p style={{fontWeight: 900, color: '#ecbdba', fontSize: 22}}>{moment(event.date).format("DD")}</p>
                    <p style={{fontWeight: 900, color: '#ecbdba', fontSize: 22}}>{moment(event.date).format("MMM")}</p>
                  </div>

                  <div style={{display: 'flex', flexDirection: 'column', width: '85%'}}>
                    <div className='quote-top-line'>
                        <p>{event.name}</p>
                        <img style={{height: 30, width: 30, objectFit:'contain'}} src={icon?.icon || discoBall} alt='First Dance Music Wedding Icon'/>
                    </div>
                    <p
                        style={{margin: '5px 0', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '95%'}}
                    >
                        {event.notes ? event.notes : "No notes added"}
                    </p>
                    <p>{event.info.startTime} - {event.info.endTime}</p>
                  </div>
                 
              </div>

            </Link>
            )
            : (
              <Link to={`requests/${event.requestId}`} key={i} style={{textDecoration: 'none', color: '#313131'}}>
              <div className='quote-dash-container' style={{backgroundColor: 'white', margin: '20px auto', width: '90%', flexDirection: 'row', alignItems: 'center', justifyContent:'space-between'}}>
                  <div className='quote-highlight' style={{backgroundColor: '#C1E1C1'}}/>

                  <div style={{ textAlign: 'center', paddingRight: 15}}>
                    <p style={{fontWeight: 900, color: '#C1E1C1', fontSize: 22}}>{moment(event.date).format("DD")}</p>
                    <p style={{fontWeight: 900, color: '#C1E1C1', fontSize: 22}}>{moment(event.date).format("MMM")}</p>
                  </div>
                  <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                    <div className='quote-top-line'>
                        <p>{event.title}</p>
                        <img style={{height: 30, width: 30, objectFit:'contain'}} src={discoBall} alt='First Dance Music Wedding Icon'/>
                    </div>
                  
                    <h3 style={{margin: 0, color: '#C1E1C1'}}>Booked</h3>
                  </div>
              </div>
            </Link>
            )
          })
        
        }
   
    </div>
  )
}
