import React from 'react'
import './HowItWorks.css'
import decoration from '../../../Assets/how-it-works.png'
import rectangle from '../../../Assets/Rectangle.png'
import { Link } from 'react-router-dom'

export default function HowItWorks() {
  return (
    <div id='how-it-works'>
        <h2 id='how-it-works-header'>How It Works</h2>
        <p style={{width: 600, maxWidth: '100%', fontWeight: 600, fontSize: 18, color: '#313131', marginBottom: 100}}>With First Dance Music, planning the music for your wedding is simple, stress-free, and enjoyable. Create unforgettable memories for your special day in just a few easy steps!</p>
        <img id='decoration' src={decoration}/>

        <div id='steps'>
          <img id='rectangle' src={rectangle}/>

          <div style={{display:'flex', flexWrap: 'wrap'}}>
              <div className='how-it-works-step'>
                <h2>01.</h2>
                <h3>Post your wedding date</h3>
                <p>Begin by posting your wedding date on our platform. This allows us to match you with available musicians for your special day.</p>
              </div>
              <div className='how-it-works-step'>
                <h2>02.</h2>
                <h3>Receive quotes from artists</h3>
                <p>Sit back and relax as you receive quotes from all the talented artists available on your chosen date. Our wide selection ensures you'll find the perfect match for your wedding vibe..</p>
              </div>
              <div className='how-it-works-step'>
                <h2>03.</h2>
                <h3>Pick the right artist for you</h3>
                <p>Visit our artists page to explore profiles and listen to samples from each musician, choose the artists whose style resonates with you. Check out our artists page here.</p>
              </div>
              <div className='how-it-works-step'>
                <h2>04.</h2>
                <h3>Secure your chosen musician</h3>
                <p>Once you've found the ideal musician, it's time to book! You have the flexibility to pay upfront or spread the cost over time* with our no interest and no credit checks required!</p>
                <p style={{fontSize: 12}}>*Wedding must be more than 3 months in advance of booking date and payments must finish one month before the wedding date</p>
              </div>

              <div>
                <Link to={'/find'} className='hero-btn'>Post Wedding</Link>
                <Link to={'/find/artists'} className='hero-btn' id='inverted-hero-btn'>Search Artists</Link>

              </div>
          </div>


        </div>
    </div>
  )
}
