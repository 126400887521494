import React from 'react'
import step1 from '../../../Assets/step1.png'
import step2 from '../../../Assets/step2.png'
import step3 from '../../../Assets/step3.png'
import step4 from '../../../Assets/step4.png'
import dj from '../../../Assets/dj.webp'

export default function ArtistHowItWorksMobile({ setOpen }) {
  return (
    <div id='how-it-works-mobile'>
        <h2 id='how-it-works-header'>Artist Sign Up</h2>
        <p style={{ fontWeight: 600, fontSize: 18, color: '#313131', marginBottom: 50}}>With First Dance Music, managing your bookings is effortless. You can view all your events in your calendar, communicate directly with clients through the integrated messenger tool, and eliminate the need to chase payments.</p>
        <div className='mobile-step'>
            <img style={{position: 'relative', left: '-2rem'}} className='mobile-step-img' src={step1}/>
            <div className='how-it-works-step'>
                <h2>01.</h2>
                <h3>Create Acts</h3>
                <p>Create as many acts as you like under one profile. Whether you DJ, play guitar, or sing, you can manage everything in a single account. Upload images, videos, and setlists to showcase your talents effectively.</p>
            </div>
        </div>
        <div className='mobile-step'>
            <div className='how-it-works-step'>
                <h2>02.</h2>
                <h3>Provide Quotes</h3>
                <p>Receive quote requests directly or for any act within your category. Just fill out the quote form and send it along with a friendly message.</p>
            </div>
            <img style={{position: 'relative', right: '-2rem'}} className='mobile-step-img' src={step2}/>
        </div>
        <div className='mobile-step'>
            <img style={{position: 'relative', left: '-2rem'}} className='mobile-step-img' src={step3}/>
            <div className='how-it-works-step'>
                <h2>03.</h2>
                <h3>Communicate</h3>
                <p>Communicate directly with clients using the integrated messaging tool, ensuring that both you and your clients have all the necessary information for the big day.</p>
            </div>
        </div>
        <div className='mobile-step'>
            <div className='how-it-works-step'>
                <h2>04.</h2>
                <h3>Confirm Bookings</h3>
                <p>Once the client agrees to proceed, we will begin collecting the funds, which you can withdraw in full after the event.</p>
            </div>
            <img style={{position: 'relative', right: '-2rem'}} className='mobile-step-img' src={step4}/>
        </div>

        <button className='hero-btn' onClick={() => setOpen(true)}>Sign Up Now</button>


        <img style={{position: 'relative', left: '-2rem'}} id='rectangle-mobile' src={dj}/>



    </div>
  )
}
