import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import close from '../../../Assets/Icons/close.png'
import '../../PublicUI/Modals/Modal.css'


export default function ModalManageTicket({ selectedChat, onClose }) {

    const [saving, setSaving] = useState(false)
    const [ticketUpdated, setTicketUpdated] = useState(false)
    const [ticket, setTicket] = useState("")
    const [message, setMessage] = useState("")
    const [ticketStatus, setTicketStatus] = useState("")

    useEffect(() => {
        getTicket()
    }, [])

    const getTicket = () => {
        axios.post(`${process.env.REACT_APP_API}/chats/get-ticket-by-id`, {ticketId: selectedChat?.ticketId})
        .then((res) => {
            setTicket(res.data)
            setTicketStatus(res.data.status)
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const handleContainerClick = (e) => {
        // Prevent click event from bubbling up to the modal div
        e.stopPropagation();
    };

    const closeModal = () => {
        setTicketUpdated(false)
        onClose()
    };

    const handleUpdateTicket = () => {
        setSaving(true)

        let payload = {
            chat: selectedChat,
            status: ticketStatus,
            message: message,
        }

        axios.post(`${process.env.REACT_APP_API}/chats/update-support-ticket`, payload)
        .then((res) => {
            if (res.data === 'Ticket updated') {
                setTicketUpdated(true)
                setMessage("")
                setTicketStatus("")
                getTicket()
            }
            setSaving(false)
        })
        .catch((error) => {
            console.log("Update of support ticket failed.", error)
            toast.error("Update of support ticket failed.")
            setSaving(false)
        })
    }

    return (
        <div className='modal' onClick={closeModal}>
            <div className='modal-container' onClick={handleContainerClick}>
                <img className='hover close-modal-btn' src={close} alt='Close First Dance Music box' onClick={closeModal}/>
                <h2 className='modal-header'>Update Ticket</h2>
                <div className='divider' />

                <div className='modal-ticket-container'>
                    <div className='modal-description'>
                        <p className='modal-txt'>
                            <span className='modal-label'>Customer name:</span> {selectedChat.clientName || selectedChat.artistName}
                        </p>
                        <p className='c'>
                            <span className='modal-label'>Customer email:</span> {selectedChat.clientEmail || selectedChat.artistEmail}
                        </p>

                        <div className='modal-txt'>
                            <p className='modal-title'>Ticket history:</p>
                            <div className='notes-container'>
                            {ticket.notes && ticket.notes.length > 0 ? (

                                ticket.notes?.map((note, index) => (
                                    <div key={index} className='note'>
                                        <p>{note.text}</p>
                                    </div>
                                ))
                                ) : (
                                    <p className='no-notes'>No notes entered yet</p>
                                )}
                            </div>
                        </div>

                        <div className='modal-txt'>
                            <p className='modal-title'>Enter support note (internal only):</p>
                            <textarea
                                placeholder={ticketUpdated ? '' : 'Type your message'}
                                className='modal-textarea'
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            />
                        </div>
                            <div className='modal-txt'>
                            <p className='modal-title'>Ticket Status:</p>
                            <select
                                className='modal-dropdown'
                                value={ticketStatus}
                                onChange={(e) => setTicketStatus(e.target.value)}
                            >
                                <option value="open">Open</option>
                                <option value="resolved">Resolved</option>
                            </select>
                        </div>
                    </div>

                    {(ticketUpdated) ? (
                        <p style={{fontSize: 18}}>Support Ticket has been updated</p>
                    ) : (
                        <button
                            className='modal-btn'
                            onClick={handleUpdateTicket}
                            disabled={saving}
                        >
                            {saving ? 'Updating ticket...' : "Update ticket"}
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}
