import React from 'react'
import './Hero.css'
import hero from '../../../Assets/temp-landing.png'
import heroMobile from '../../../Assets/hero-mobile.webp'
import finance from '../../../Assets/Icons/0.png'
import credit from '../../../Assets/Icons/credit.png'
import artists from '../../../Assets/Icons/artists.png'
import { Link } from 'react-router-dom'

export default function Hero() {

    const handleClickScroll = (id) => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };

  return (
    <div id='hero'>
        <div id='hero-left'>
            <h1>Effortlessly book music for your wedding</h1>
            <p>Set the perfect tone for your wedding with our ever-growing selection of talented artists</p>

            <div>
                <Link to={'/find/artists'} className='hero-btn'>Find Artists</Link>
                <button className='hero-btn' id='inverted-hero-btn' 
                    onClick={() => {
                        if(window.innerWidth < 1000) {
                            handleClickScroll('how-it-works-mobile')
                        } else {
                            handleClickScroll('how-it-works')
                        }
                        }}
                >
                        Learn More
                </button>
            </div>
            
            <img id='hero-mobile' src={heroMobile}/>

            <div id='hero-highlights'>
                <div className='hero-highlight' style={{paddingLeft: 0}}>
                    <img src={finance} className='hero-icon'/>
                    <p>Spread the cost with no interest</p>
                </div>
                <div className='divider-vertical' />
                <div className='hero-highlight'>
                    <img src={credit} className='hero-icon'/>
                    <p>no credit checks required</p>
                </div>
                <div className='divider-vertical' />
                <div className='hero-highlight'>
                    <img src={artists} className='hero-icon'/>
                    <p>wide selection of artists</p>
                </div>
            </div>
        </div>

        <div id='hero-right'>
            <img id='hero-desktop' src={hero} style={{width: '100%'}}/>
        </div>

    </div>
  )
}
