import React, { useState } from 'react'
import './ArtistFAQs.css'
import hearts from '../../../Assets/bg-hearts.png'

let questions = [
    {q: "When do musicians get paid?", a: "All funds get held in a client money bank account until the event has been fulfilled. You will then be paid the full amount within 5 working days to your nominated bank account. " },
    {q: "What if we need to cancel?", a: "Everyone knows how important weddings are but if you absolutely need to cancel the bookings please reach out to us urgently via our support tool."},
    {q: "Are there any hidden fees?", a: "No hidden fees! Our platform fee gets passed on to the client, ensuring our musicians get paid the full amount that they quote."},
    {q: "Who manages our account?", a: "You are solely responsible for managing your profiles and communications with clients. We are here to help bring in more work and give you the admin tools to easily manage your bookings."},
    {q: "What if I need to make changes to my booking?", a: "If you have a simple change that doesn't affect your quote, please inform the client via the app. Otherwise please contact one of the First Dance team via our support tool."}
]

export default function ArtistFAQs() {

    const [open, setOpen] = useState("")

    const handleClick = (index) => {
        if(open === index){
            setOpen("")
        } else {
            setOpen(index)
        }
    }

  return (
    <div id='artist-faqs-container'>
        <h2>Frequently Asked Questions</h2>
        <img id='bg-artist-hearts'  src={hearts}/> 
        {questions.map((item, i) => {
                return (
                    <div key={i} className={open === i ? 'faq-container-open' : 'faq-container'} onClick={() => handleClick(i)}>
                        <div style={{display:'flex', alignItems: 'center'}}>
                            <div className={`icon ${open === i ? 'open' : ''}`}></div>

                            <h6>{item.q}</h6>
                        </div>
                        <p className={open === i ? 'open-artist-answer' : 'close-artist-answer'} >{item.a}</p>
                    </div>
                )
            })}
    </div>
  )
}
