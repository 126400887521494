import React, { useEffect, useState} from 'react'
import { useAuth } from '../../Context/AuthContext'
import axios from 'axios'
import moment from 'moment'
import './Inbox.css'
import { useNavigate, useParams, Outlet, useLocation } from 'react-router-dom';
import envelope from '../../Assets/Icons/envelope.png'
import envelopeOpen from '../../Assets/Icons/envelope-open.png'

export default function ArtistInbox() {
  let navigate = useNavigate()
  let { id } = useParams()
  let location = useLocation()
  let pathname = location.pathname
  let { currentUser } = useAuth()

  const [chats, setChats] = useState([])
  const AdminSupportName = "Admin Support"
  const [inboxOpen, setInboxOpen] = useState(pathname === '/artists/inbox' ? true : false)
  
 
  useEffect(() => {
    getChats()
  },[])

  const getChats = () => {
    axios.post(`${process.env.REACT_APP_API}/chats/get-chats-by-id`, {id: currentUser.uid})
      .then((res) => {
        setChats(res.data)
      })
      .catch((error) => console.log(error))
  }

  return (
    <div>
      <div id='inbox-side-panel' className={inboxOpen ? "inbox-open" : ""}>
        <h1>Inbox</h1>
        {chats.length < 1 ? (
          <div style={{textAlign:'center'}}>
            <p style={{ textAlign: 'center' }}>Inbox empty</p>
            <button onClick={() => setInboxOpen(false)} className='primary-btn'>Close</button>
          </div>
        ) : (
          chats
            .slice() // Create a shallow copy of the array to avoid mutating the original
            .sort(function(a, b) {
              return new Date(b.lastMsgAdded) - new Date(a.lastMsgAdded);
            })
            .map((chat, i) => {
              return (
                <div key={i} className={chat._id == id ? 'inbox-preview-active hover' : 'inbox-preview hover'}  onClick={() => {navigate(`/artists/inbox/${chat._id}`); setInboxOpen(false)}}>
                  
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p className='inbox-list-name' style={chat.artistRead ? {color: '#808080'} : { color: 'black'} }>{chat.ticketId? AdminSupportName : chat.clientName}</p>
                      <img className='inbox-envelope' src={chat.artistRead ? envelopeOpen : envelope}/>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end', position: 'relative' }}>
                      <p className='inbox-preview-msg' style={chat.artistRead ? {color: '#808080'} : { color: 'black'} }>
                        {!chat.lastMsg ? "No message preview available" : chat.lastMsg}
                      </p>
                      <p style={{ textAlign: 'right', fontSize: 12, color: '#808080', margin: 0, position:'absolute', right: 0, bottom: 0 }}>
                        {moment(chat.lastMsgAdded).fromNow()}
                      </p>
                    </div>
                  
                </div>
              );
            })
        )}
      </div>

      <div id='inbox-tab' onClick={() => setInboxOpen(true)}>
          <p style={{margin:0}}>Inbox</p>
          <img style={{width: 20, filter: 'invert(1)', marginLeft: 10, objectFit:'contain'}}  src={envelope}/>
      </div>

      <Outlet context={{ getChats }}/>
      

    </div>
  )
}
