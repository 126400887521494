import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import React, { useRef, useEffect, useState } from "react";
import { TextField } from '@mui/material';
import axios from 'axios'
import { toast } from 'react-toastify';
import stripeLogo from '../../Assets/stripe.png'
import { LogToServer } from '../../Utilities'
import './PaymentForm.css'


function PaymentForm(props) {
    const { request, customerGross, quote, onSuccess } = props;

    // thresholds for payment plan
    const minMonthThreshold = 3
    const maxMonthThreshold = 24
    const minGrossThreshold = 250

    const stripe = useStripe()
    const elements = useElements()

    const [cardName, setCardName ] = useState(request.name)
    const [cardEmail, setCardEmail ] = useState(request.email)
    const [isProcessing, setIsProcessing] = useState(false)
    const [isProcessingInstalment, setIsProcessingInstalment] = useState(false)

    const [differenceInMonths, setDifferenceInMonths] = useState(0)
    const [numberOfMonthlyInstalments, setNumberOfMonthlyInstalments] = useState(0)
    const [firstInstalment, setFirstInstalment] = useState(0)
    const [monthlyInstalment, setMonthlyInstalment] = useState(0)
    const [monthlyPlatformFee, setMonthlyPlatformFee] = useState(0)
    const [formCalculated, setFormCalculated] = useState(false)


    // Create a ref for the payment form container div
    const formRef = useRef(null)

    // calculate the form on load
    useEffect(() => {
        calculateMonthlyInstalments()
    }, [])

    // scroll to the payment form element when it's displayed
    useEffect(() => {
        if (formRef.current) {
            formRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            });
        }

    }, [formCalculated])

    const calculateMonthlyInstalments = () => {
        // Calculate the number of instalments based on the number of months between
        // now and request.date. Instalments must be complete one full month before
        // event date. Initial payment is the deposit, instalments start 1 month later
        // and must finish at least 1 month before
        const dateNow = new Date()

        const start = new Date(dateNow);
        const end = new Date(request.date);

        // determine total span of months
        let months = (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth())

        // compensate for partial month so payments are complete at least 30 days before
        if (end.getDate() < start.getDate()) {
            months--;
        }

        setDifferenceInMonths(months)
        const numberOfMonthlyInstalments = months - 1
        setNumberOfMonthlyInstalments(numberOfMonthlyInstalments)

        // Calculate the instalment amounts
        // note that this truncates digits, the total will therefore be up to 1p less per instalment
        const calculateFirstInstalment = (customerGross * 0.1).toFixed(2)
        setFirstInstalment(calculateFirstInstalment)

        const balance = customerGross - firstInstalment

        if (quote.platformFee) {
            const calculateMonthyPlatformFee = ((quote.platformFee - firstInstalment) / numberOfMonthlyInstalments).toFixed(2)
            setMonthlyPlatformFee(calculateMonthyPlatformFee)
        }

        const calculateMonthlyInstalment = (balance / numberOfMonthlyInstalments).toFixed(2)
        setMonthlyInstalment(calculateMonthlyInstalment)

        setFormCalculated(true)
    };

    const allowMonthlyInstalments = () => {
        // Many conditions for monthly instalments
        // Some that are essential now, some that ought to come into play as and when things develop
        // Some crucial aspects:
        //  - maintaining as close to certainty that the act will be able to play. This means
        //    limiting how far in the future weddings can be accepted
        //  - reducing the impact of stripe fees. This means limiting how low monthly amounts
        //    repayments can be. Remember for each payment stripe charges a % plus a 20p charge.
        //    For £10, 20p is already up at 0.2% which is already more than the base percentage
        // Other options to allow payment spread with lower amounts
        //  - take deposit now and start monthly payments at a future date, say 6 months before wedding date
        //
        // payment ranges:
        //   - lowest payment: 250
        //   - date now of 05/06/24
        //       - first allowed 05/10/24; not allowed 05/09/24, so deposit now and then 07, 08, 09 and one month, its actually +4
        //       - last allowed 2026-07-04; not allowed 2026/07/05, so deposit now and then 23 months and one month so actually 25 months permitted
        //
        // note
        //  - payments always need to finish one month in advance of date
        //
        // lowest gross and furthest date
        // Initial 10% non-refundable depost now of £25.00
        // Followed by 23 monthly amounts of £9.38
        // Total to pay £250
        //
        // lowest gross and closest date
        // Initial 10% non-refundable depost now of £25.00
        // Followed by 3 monthly amounts of £112.50
        // Total to pay £250
        //
        // impact of stripe fees
        //   1.4% on each card payment plus 0.5% scheduled payment plus 20p
        //   1.9% if business or non-uk card plus 0.5% scheduled payment plus 20p
        // We take 10% upfront as initial payment (non refundable deposit)
        // the gross figure is artist fee plus 12.5% (AppCommission = 1.125)
        // this means we also have 2.5% contigency in each additional payment

        // Minimum date results in 4 payments total, 1 deposit now followed
        // by 3 monthly payments which is a span of 3 months, plus 1 further
        // month before wedding date, to give a total of 4
        // Maximum date results in 24 payments total, 1 deposit now followed
        // by 23 monthly payments which is a span of 24 months plus 1 further
        // month before wedding date, to give a total of 25
        return (
            (differenceInMonths > minMonthThreshold) &&
            (differenceInMonths <= maxMonthThreshold) &&
            (customerGross >= minGrossThreshold)
        )
    };

    const handlePaymentSubmit = (stripeCustomerId, paymentSummary) => {
        // successful payment - return to parent
        const paymentData = {
            message: 'Payment Successfully Submitted',
            stripeCustomerId,
            paymentSummary
        }

        // force the form to be recalculated before display
        setFormCalculated(false)

        // Call the onSuccess function passed from the parent
        onSuccess(paymentData);
    };

    const payByInstalments = async () => {
        setIsProcessingInstalment(true);
        console.log("Pay By Instalments")

        try {
            const { paymentMethod, error: pmError } = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardNumberElement),
                billing_details: {
                    email: cardEmail,
                },
            });

            // check validity of the card details entered
            if (pmError) {
                setIsProcessingInstalment(false);
                toast.error("There was a problem making the payment. " + pmError.message)
                LogToServer("Error creating payment method", pmError.message)
                return
            }

            if (paymentMethod?.card?.country !== 'GB') {
                LogToServer(`Card is non-UK client ${request.clientId} ${cardEmail}`)
                // TBD consider rejecting non-UK cards as they incur higher fees. see Om for further content
            }

            // create the payment intent for the deposit and then a subscription schedule
            const response = await axios.post(`${process.env.REACT_APP_API}/payments/create-setup-intent-for-schedule`, {
                paymentMethodId: paymentMethod.id,
                deposit: firstInstalment,
                email: cardEmail,
                name: cardName,
                clientId: request.clientId,
                quoteId: quote._id
            })

            const { clientSecret, stripeCustomerId } = response.data;

            const { setupIntent, error: csError } = await stripe.confirmCardSetup(
                clientSecret, {
                payment_method: {
                    card: elements.getElement(CardNumberElement),
                    billing_details: {
                        name: cardName,
                        email: cardEmail
                    },
                },
            })

            if (csError) {
                setIsProcessingInstalment(false)
                LogToServer('Error creating payment:', csError.message)
                toast.error("There was a problem making the payment. " + csError.message)
                return
            } else if (!setupIntent) {
                // this shouldn't really happen, if there was no error then we should have the payment intent
                // if we don't then we also should not be carrying forward to create the subscription
                setIsProcessingInstalment(false)
                LogToServer('Problem during payment confirmation. Please try again or contact support')
                toast.error("Problem during payment confirmation. Please try again or contact support")
                return
            }

            const dateNow = new Date()

            // now create the subscription
            const scheduleResponse = await axios.post(`${process.env.REACT_APP_API}/payments/create-instalments-schedule`, {
                paymentMethodId: setupIntent.payment_method, // pass in the method from the confirmation response
                monthlyPlatformFee: quote.platformFee ? monthlyPlatformFee : null,
                deposit: firstInstalment,
                monthlyInstalment,
                numberOfMonthlyInstalments,
                dateNow,
                quoteId: quote._id,
                stripeCustomerId,
            })
            let { subscriptionScheduleId, message, paymentSummary } = scheduleResponse.data

            setIsProcessingInstalment(false);
            LogToServer(`Card payment confirmed ${message}`)

            // and we are done with the payment form
            // TBD NEED TO ADD The subscriptionscheulde id into the save
            handlePaymentSubmit(stripeCustomerId, subscriptionScheduleId, paymentSummary)

        } catch (error) {
            // handle specific responses
            if (error.response && error.response.status === 402) {
                // Handle payment failure scenario here
                LogToServer('Payment failed:', error.response.data.error )
                toast.error("There was a problem making the payment. " + error.response.data.error)
            } else {
                LogToServer('Error setting up payment scheme:', error.response?.data?.error || error.message)
                toast.error("Error setting up payment scheme. " + error.response?.data?.error || error.message)
            }
            setIsProcessingInstalment(false);
        }
    }

    const makePayment = async () => {
        setIsProcessing(true);

        try {
            // check validity of the card details entered
            const { paymentMethod, error: pmError } = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardNumberElement),
            })

            if (pmError) {
                setIsProcessing(false)
                toast.error(pmError.message)
                LogToServer("Create payment method", pmError.message)
                return;
            }

            if (paymentMethod?.card?.country !== 'GB') {
                LogToServer(`Card is non-UK client ${request.clientId} ${cardEmail}`)
                // TBD consider rejecting non-UK cards as they incur higher fees. see Om for further content
            }

            if (customerGross === 0.3) {
                // ! Payment Test Case !
                LogToServer(`PAYMENT TEST for ${request.clientId}`)
            }

            const response = await axios.post(`${process.env.REACT_APP_API}/payments/create-payment-intent`, {
                customerGross,
                platformFee: quote.platformFee,
                email: cardEmail,
                name: cardName,
                clientId: request.clientId,
                quoteId: quote._id
            });

            const { client_secret, stripeCustomerId, message, paymentSummary } = response.data;

            // confirm the payment using the payment intent secret
            // any of the three elements can be used
            const { paymentIntent, error: cpError  } = await stripe.confirmCardPayment(
                client_secret, {
                    payment_method: {
                        card: elements.getElement(CardNumberElement),
                        billing_details: {
                            name: cardName,
                            email: cardEmail
                        },
                    },
                }
            )

            if (cpError) {
                setIsProcessing(false)
                LogToServer('Error creating payment:', cpError.message)
                toast.error("There was a problem making the payment. " + cpError.message)
            } else if (paymentIntent) {
                setIsProcessing(false)
                LogToServer(`Card payment confirmed ${message}`)

                // and we are done with the payment form
                handlePaymentSubmit(stripeCustomerId, paymentSummary)
            } else {
                // this shouldn't really happen, if there was no error then we should have the payment intent
                setIsProcessing(false);
                LogToServer('Potential problem. Please contact support to confirm payment success')
                toast.error("Potential problem. Please contact support to confirm payment success")
            }
        } catch (error) {
            LogToServer('Error creating payment:', error.response?.data?.error || error.message)
            toast.error("There was a problem making the payment. " + error.response?.data?.error || error.message)
            setIsProcessing(false);
        }
    };


    return (
        <div>
          {formCalculated ? (
           <>

            <div style={{ height: '20px' }} />
            <div
                style={{
                    background: '#f0f0f0',
                    padding: '10px',
                    borderRadius: '10px',
                    width: 600,
                    maxWidth: '95vw',
                    margin: '0 auto'
                }}
            >
                <div ref={formRef}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ alignItems: 'center', width: 600 }}>
                            <h2 style={{ textAlign: 'center' }}>Payment Details</h2>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <TextField
                                    sx={{m:'10px', width:'46%'}}
                                    label='Name on card'
                                    value={cardName}
                                    size='small'
                                    onChange={(e) => setCardName(e.target.value)}
                                />
                                <TextField
                                    sx={{m:'5px', width:'46%'}}
                                    disabled
                                    label='Email'
                                    value={cardEmail}
                                    size='small'
                                    onChange={(e) => setCardEmail(e.target.value)}
                                />
                            </div>
                            <div className="card-element-container">
                                <div className="card-element full-width">
                                    <CardNumberElement />
                                </div>
                                <div className="card-element narrow-width">
                                    <CardExpiryElement />
                                </div>
                                <div className="card-element narrow-width">
                                    <CardCvcElement />
                                </div>
                                <div className="stripe-attribution">
                                    <a
                                        href="https://stripe.com"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                    <img src={stripeLogo} alt="Stripe logo"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex-container">
                        {allowMonthlyInstalments() ? (
                            <div className="payment-options">
                                <div  className="payment-column">
                                    <p className="smallParagraph" style={{fontWeight: 'bold'}}>Pay by monthly instalments up to your wedding date at 0%:</p>
                                    <p className="smallParagraph">Initial 10% <strong>non-refundable</strong> depost now of £{firstInstalment}</p>
                                    <p className="smallParagraph">Followed by {numberOfMonthlyInstalments} monthly amounts of £{monthlyInstalment}</p>
                                    <p className="smallParagraph">Total to pay £{customerGross}</p>
                                    <button id="payment-btn"
                                        disabled={isProcessingInstalment}
                                        onClick={payByInstalments}
                                    >
                                        {isProcessingInstalment ? "Submitting..." : "Pay by Instalments"}
                                    </button>
                                </div>
                                <div className="payment-column payment-column-stretch">

                                    <p className="smallParagraph" style={{fontWeight: 'bold'}}>
                                        Pay full amount now:
                                    </p>
                                    <p className="smallParagraph" >Inclusive of 10% <strong>non-refundable</strong> amount.</p>

                                    <p className="smallParagraph">
                                        Total to pay £{customerGross}
                                    </p>
                                    <button
                                        id="payment-btn"
                                        disabled={isProcessing}
                                        onClick={makePayment}
                                    >
                                        {isProcessing ? "Submitting..." : "Pay in Full"}
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div style={{ textAlign: 'center' }}>
                                <p className="smallParagraph" style={{fontWeight: 'bold'}}>
                                    Total to pay: £{customerGross}
                                </p>
                                <p className="smallParagraph" >Inclusive of 10% <strong>non-refundable</strong> amount.</p>
                                    <button
                                        id="payment-btn"
                                        disabled={isProcessing}
                                        onClick={makePayment}>
                                        {isProcessing ? "Submitting..." : "Pay in Full"}
                                    </button>
                            </div>
                        )}
                    </div>
                </div>
                <div style={{ height: '20px' }} />
            </div>
            <div style={{ height: '20px' }} />
           </>
          ) :
            null
          }
        </div>
    )
}

export default PaymentForm;
