import React from 'react'
import { useNavigate } from 'react-router-dom'
import heart from '../../Assets/broken-heart.png'
import './PageNotFound.css'
import Footer from './Footer'
import Navbar from './Navbar'

export default function PageNotFound() {

    let navigate = useNavigate('/')

  return (
    <>
    <Navbar />
    <div id='page-not-found'>
        <h2>Oops! Page not found</h2>
        <img id='broken-heart' src={heart}/>
        <button id='not-found-btn' onClick={() => navigate('/')}>Return Home</button>
    </div>
    <Footer />
    </>
  )
}
