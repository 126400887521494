import axios from 'axios'

// Function to extract text from URL
export const ExtractEmbedFromVideoUrl = (videoLink) => {

    const youtubeRegex = new RegExp(
        [
            // Protocol (enforce https presence, http is not expected but also accepted)
            '(?:https?:\\/\\/)',

            // Domain (various YouTube URLs)
            '(?:',
            'youtu\\.be\\/',                                              // youtu.be/
            '|www\\.youtube\\.com\\/embed\\/',                            // www.youtube.com/embed/
            '|www\\.youtube\\.com\\/v\\/',                                // www.youtube.com/v/
            '|www\\.youtube\\.com\\/watch\\?v=',                          // www.youtube.com/watch?v=
            '|www\\.youtube\\.com\\/watch\\?feature=player_embedded&v=',  // www.youtube.com/watch?feature=player_embedded&v=
            '|www\\.youtube\\.com\\/shorts\\/',                           // www.youtube.com/shorts/
            '|youtube\\.com\\/shorts\\/',                                 // youtube.com/shorts/
            ')',

            // Video ID is expected after each of these
            // dommain (youtube standard of 11 characters)
            '(?<videoId>[A-Za-z0-9_-]{11})',

            // Optional trailing slash
            '\\/?'
        ].join(''),
    );

    const match = videoLink.match(youtubeRegex);

    if (match && match.groups && match.groups.videoId) {
        return match.groups.videoId;
    } else {
        return ""
    }
}

export const SplitName = (name) => {
    // Remove leading and trailing spaces
    name = name.trim();

    // Split the name by spaces
    const parts = name.split(/\s+/);

    if (parts.length === 1) {
        // Only one part, consider it as the firstName
        return { firstName: parts[0], lastName: '' };
    } else {
        // More than one part, consider the last part as the lastName
        const lastName = parts.pop();
        const firstName = parts.join(' ');
        return { firstName, lastName };
    }
}


//  -- log client info and errors to server --
// Idea is to obtain persistent copy of errors in clients browser and/or trace
// of events. Use of an external service should be considered for the future
// Can be done as fire and forget, if it fails, there's not much to be done
// and it certainly should not affect the user experience
// Should ony be done where no info is already being set on the backend, so
// for instance it is redundant to log results of server endpoints errors
export const LogToServer = (message, error) => {
    console.log(message, error ? error : '');

    axios.post(
        `${process.env.REACT_APP_API}/system/log-message`,
        {
            message,
            error,
        }
    ).catch((loggingError) => {
        console.error('Problem logging, not much to be done about that:', loggingError);
    })
}
