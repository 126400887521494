import React from 'react'
import './Tabs.css'

export default function WeddingTabs({tab, setTab}) {
  return (
    <div className='wedding-tabs'>
            <div 
                className={tab === 1 ? 'wedding-tab-active' : 'wedding-tab'}
                onClick={() => setTab(1)}
            >
                <p>Requests</p>
            </div>
            <div 
                className={tab === 2 ? 'wedding-tab-active' : 'wedding-tab'}
                onClick={() => setTab(2)}
            >
                <p>Bookings</p>
            </div>
    </div>
  )
}
