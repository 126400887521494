import React, { useState, useEffect } from 'react'
import close from '../../../Assets/Icons/close.png'
import { WeddingSchedule } from '../../../Constants';
import { toast } from 'react-toastify'
import axios from 'axios'
import { useAuth } from '../../../Context/AuthContext';

export default function AddToScheduleModal({setOpen, getSchedule, scheduleId, events}) {

  const [event, setEvent] = useState("")
  const [eventName, setEventName] = useState("")
  const [start, setStart] = useState("")
  const [end, setEnd] = useState("")
  const [notes, setNotes] = useState("")
  const [loading, setLoading] = useState(false)

  let { currentUser } = useAuth()

  useEffect(() => {
    if(event){
        let selected = WeddingSchedule.find(item => item.id ==  event)
        setEventName(selected.name)
    }

  }, [event])

  const handleContainerClick = (e) => {
    e.stopPropagation(); // Prevents click event from bubbling up to the modal div
  };

  const handleSubmit = () => {
    if(!event || !start){
        toast.error("Please add event and start time")
    } else {
        setLoading(true)
        let newEvent = {
            eventId: event,
            event: eventName,
            startTime: start,
            endTime: end,
            notes,
            visible: true
        }

        let newEventsArray = events ? [...events, newEvent] : [newEvent];
        
        const payload = scheduleId 
        ? { scheduleId, events: newEventsArray } 
        : { events: newEventsArray, clientId: currentUser.uid };

        axios.post(`${process.env.REACT_APP_API}/bookings/add-event-to-schedule`, payload)
        .then(() => {
            setOpen(false)
            getSchedule()
        })
        .catch((e) => {
            toast.error("Something went wrong")
        })
        .finally(() => {
            setLoading(false)
        })
    }
  }

  return (
    <div className='modal' onClick={(e) =>  setOpen(false)}>

        <div className='modal-container' onClick={handleContainerClick}>
          <img className='close-modal-btn hover' src={close} alt='Close First Dance Music modal' onClick={() => setOpen(false)}/>

          <h3 className='modal-header' style={{color: '#313131'}}>Add to Wedding Planner</h3>

          <p>Build your wedding day to help you keep on top of planning every element.</p>

            <select 
                className='single-input' 
                style={{margin: '10px 0'}} 
                value={event} 
                onChange={(e) => setEvent(e.target.value)}
            >
                <option value="" disabled>Select</option>
                {WeddingSchedule.map((item, i) => {
                    return (
                        <option key={i} value={item.id}>{item.name}</option>
                    )
                })}
            </select>
            
            <div style={{display:'flex', justifyContent:'space-between'}}>
                <p className='modal-label'>From</p>
                <p style={{textAlign:'right'}} className='modal-label'>To (Optional)</p>
            </div>

            <div style={{display:'flex', marginBottom: 10}}>
                <input 
                    type='time' 
                    className='single-input' 
                    style={{width: '50%', borderRadius: '8px 0 0 8px'}} 
                    value={start} 
                    onChange={(e) => setStart(e.target.value)}
                />
                <input 
                    type='time' 
                    className='single-input' 
                    style={{width: '50%', borderRadius: '0 8px 8px 0', borderLeft: 'unset'}} 
                    value={end} 
                    onChange={(e) => setEnd(e.target.value)}
                />
            </div>

            <textarea 
                style={{resize: 'none', paddingTop: 10, height: 100, marginTop: 10}} 
                className='single-input' 
                placeholder='Notes' 
                value={notes} 
                onChange={(e) => setNotes(e.target.value)}
            />

          <button className='modal-btn' onClick={handleSubmit} disabled={loading}>
            {loading ? 'Saving...' : "Save"}
          </button>

        </div>

    </div>
  )
}
