import React, { useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import InputAdornment from '@mui/material/InputAdornment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import moment from 'moment'
import { toast } from 'react-toastify'
import { useAuth } from '../../Context/AuthContext'
import { LogToServer } from '../../Utilities'


// Description:
//   Page allows an artist to provide a quote in response to a client's request
//   Can also be revisited to update a previously supplied quote
//   Once a quote has been accepted (paid) this page will reflect that, no further
//   updates are permitted

export default function Request() {
    const vatRateLow = 1.0
    const vatRateHigh = 1.20
    const AppCommission = 0.125
    const MinCommission = 30
    const MaxCommission = 500
    let { currentUser } = useAuth()
    let { id } = useParams()
    const [quoteId, setQuoteId] = useState(null)
    const [artistFee, setArtistFee] = useState(0)
    const [car, setCar] = useState(0)
    const [food, setFood] = useState(0)
    const [accommodation, setAccommodation] = useState(0)
    const [vat, setVat] = useState(vatRateLow)
    const [hasPaid, setHasPaid] = useState(false)
    const [selectedActId, setSelectedActId] = useState("")
    const [acceptedActName, setAcceptedActName] = useState("")
    const [msg, setMsg] = useState("")
    const [artistName, setArtistName] = useState("")
    const [artistEmail, setArtistEmail] = useState("")
    const [request, setRequest] = useState("")
    const [quotes, setQuotes] = useState([])
    const [acts, setActs] = useState([])
    const [requestRejected, setRequestRejected] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)

    useEffect(() => {
        getRequest()
        getArtistInfo()
    }, [])

    useEffect(() => {
        if ((currentUser.uid) && (request._id))
            getQuotes()
    }, [currentUser.uid, request._id])

    useEffect(() => {
        // once we have the artist info, retrieve the act info
        // any change to payment status, retrieve this again
        if (artistEmail)
            getActInfo()
    }, [artistEmail, hasPaid])

    const getRequest = () => {
        axios.post(`${process.env.REACT_APP_API}/requests/get-request-by-id`, {id})
        .then((res) => {
            setRequest(res.data)

            // in the case of an artist specific request the act will already
            // be specified by the client and should be pulled into the quote
            if (res.data.actName) {
                setSelectedActId(res.data.actName)
            }

            // determine archived state
            const isArchived = res.data.archived?.some(
                (archiveEntry) => archiveEntry.artistId === currentUser.uid
            );
            setRequestRejected(isArchived)
        })
        .catch((error) => console.log("Error getting request by id", error))
    }

    const getArtistInfo = () => {
        axios.post(`${process.env.REACT_APP_API}/users/get-artist-by-id`, {id: currentUser.uid})
        .then((res) => {
            let {firstName, lastName, email} = res.data
            setArtistName(`${firstName} ${lastName}`); setArtistEmail(email)
        })
        .catch((error) => console.log("Error getting artist by id", error))
    }

    // retrieve all acts for the artist
    const getActInfo = () => {
        axios.post(`${process.env.REACT_APP_API}/acts/get-acts-by-id`, {
            artistId: currentUser.uid,
            published: true
        })
        .then((res) => {
            setActs(res.data);

            if (hasPaid) {
                const matchingAct = res.data.find(act => act._id === selectedActId);
                setAcceptedActName(matchingAct.actName)
            }
        })
        .catch((error) => console.log("Error getting acts by id", error))
    }

    const getQuotes = (selectedAct) => {
        // get all the quotes for the artist for that request
        axios.post(`${process.env.REACT_APP_API}/quotes/get-artist-quotes-for-request`, {
            artistId: currentUser.uid,
            requestId: request._id
        })
        .then((res) => {
            setQuotes(res.data)

            if (res.data.length > 0) {
                // if we are manually selecting a quote then display that
                // or set the initial quote state depending on whether one has been paid
                // and otherwise default to the first in the list
                if (selectedAct) {
                    // request has been fulfilled and paid so default to showing those details
                    const matchingQuote = quotes.find(quote => quote.selectedActId === selectedAct)

                    setQuoteId(matchingQuote?._id ?? null)
                    setArtistFee(matchingQuote?.artistFee ?? 0)
                    setCar(matchingQuote?.mileageFee ?? 0)
                    setFood(matchingQuote?.foodFee ?? 0)
                    setAccommodation(matchingQuote?.accommodationFee ?? 0)
                    setVat(matchingQuote?.vatRate ?? vatRateLow)
                    setMsg(matchingQuote?.message ?? "")
                } else if (request.quoteId) {
                    // request has been fulfilled and paid so default to showing those details
                    const matchingQuote = res.data.find(quote => quote._id === request.quoteId)

                    setQuoteId(matchingQuote?._id ?? null)
                    setArtistFee(matchingQuote?.artistFee ?? 0)
                    setCar(matchingQuote?.mileageFee ?? 0)
                    setFood(matchingQuote?.foodFee ?? 0)
                    setAccommodation(matchingQuote?.accommodationFee ?? 0)
                    setVat(matchingQuote?.vatRate ?? vatRateLow)
                    setMsg(matchingQuote?.message ?? "")
                    setSelectedActId(matchingQuote?.selectedActId)

                    // if there is a matching quoteId then the stored hasPaid should be true
                    // and it should be locked to this state, no way it can be set false
                    setHasPaid(matchingQuote.hasPaid)
                } else {
                    setQuoteId(res.data[0]._id ?? null)
                    setArtistFee(res.data[0].artistFee ?? 0)
                    setCar(res.data[0].mileageFee ?? 0)
                    setFood(res.data[0].foodFee ?? 0)
                    setAccommodation(res.data[0].accommodationFee ?? 0)
                    setVat(res.data[0].vatRate ?? vatRateLow)
                    //setMsg(res.data.message ?? "")
                    setSelectedActId(res.data[0].selectedActId)
                }
            }
        })
        .catch((error) => {
            console.log("Error getting artist quote for request", error)
        })
    }

    const handleActChange = (selectedAct) => {
        setSelectedActId(selectedAct)

        // Search for the act just selected, there should only be either 0 or 1 occurrences
        // If 0 then clear the form, this is a new quote for creation
        // If 1 then this is a quote amendment and set fields to the associated quote
        // If a quote is paid then that is always the default act displayed
        // Any alternative acts quoted can still be displayed but the title
        // makes it clear which act is associated with the payment
        getQuotes(selectedAct)
    }

    let total = (artistFee * 1) + (car* 1 ) + (food * 1) + (accommodation * 1)

    // ! allow for Payment Test Case !
    // can make a test booking for 30p which is the stripe min
    let gross = (total === 0.3) ? 0.3 : Math.round(total * vat)


    const roundToNearest = (amountToRound, roundPoint) => {
        return Math.round(amountToRound / roundPoint) * roundPoint
    }

    function calculateCustomerGross(gross, AppCommission) {
        // Apply app commission to the gross artist cost for saving with the quote.
        // But first enforce the minimum and maximum commission and also round this
        // figure to a customer friendly value
        let commission = gross * AppCommission;
        if (commission < MinCommission) {
            commission = MinCommission
        } else if (commission > MaxCommission) {
            commission = MaxCommission
        }

        const total = gross + commission
        return roundToNearest(total, 5)
    }

    const sendQuote = () => {

        // Guard against messge text that includes pricing that might conflict with the quote (due
        // to the inclusion of the platform fee) and anything hinting at backdooring the platform
        // Specifics here: identify an email match, a possible phone number, and the artist gross (without pence)

        // check for outright rejection of message content
        let errorString
        if (msg.includes(gross.toString())) {
            errorString = "Please do not duplicate quote prices in your message text."
        }
        else if (msg.includes(artistEmail)) {
            errorString = "Use in-app messaging for contact at this time in preference to direct email in your message text."
        }

        if (errorString) {
            toast.error(errorString)

            LogToServer(`Attempt to include illegal items in quote message by artist ${artistEmail}`, errorString)
            return
        }

        // check for warnable content in the message
        if (
            msg.match(/\b\d{6,}\b/) ||
            msg.match(/[a-zA-Z]+@[a-zA-Z]+/)
        ) {
            errorString = "Use in-app messaging for contact at this time."
            const userConfirmed = window.confirm(
                "Message text advisory:\n\n" +
                "Communication is securely handled by your app's inbox and is best managed there.\n\n" +
                "If you feel your message is good to go then select OK to continue, otherwise cancel and amend as needed."
            )

            LogToServer(`Possible backdoor content in quote message by client ${request.clientId}`, "Suspect content in quote message")

            if (!userConfirmed) {
                return
            }
        }

        if (!total) {
            const userConfirmed = window.confirm(
                "You have created a £0 (free) quote.\n\n" +
                "If you are sure then select OK to continue, otherwise cancel and amend as needed."
            )

            if (!userConfirmed) {
                return
            }
        }

        if (!selectedActId) {
            toast.error("Please select act")
        } else {
            setIsProcessing(true)

            let customerGross
            if (gross === 0) {
                // this is considered a free booking, there should be no commission
                customerGross = 0
            } else if (gross === 0.3) {
                // ! Payment Test Case !
                // can make a test booking for 30p which is the stripe min
                customerGross = 0.3
            } else {
                customerGross = calculateCustomerGross(gross, AppCommission)
            }

            const platformFee = customerGross - gross

            // if no previous quote then the quoteId will be undefined and this will form a create
            // if update then potentially we are overwriting existing values with the same ones
            // since all are always applied
            let quotePayload = {
                quoteId,
                artistId: currentUser.uid,
                requestId: request._id,
                artistFee,
                mileageFee: car,
                foodFee: food,
                accommodationFee: accommodation,
                vatRate: vat,
                customerGross: customerGross,
                platformFee: platformFee,
                hasPaid: false,
                selectedActId: selectedActId,
                message: msg,
                updatedAt: new Date()
            }

            let requestPayload = {
                artistId: currentUser.uid,
                artistName,
                artistEmail,
                net: total,
                vat,
                msg,
                clientId: request.clientId,
                clientEmail: request.email,
                clientName: request.name,
                requestId: request._id
            }

            axios.post(`${process.env.REACT_APP_API}/quotes/send-initial-quote`, { requestPayload, quotePayload } )
            .then((res)=>{
                toast.success(res.data)
                clearForm()
            })
            .catch((error) => {
                console.log("Error during quote creation/amendment", error)
                toast.error(error.response.data.error || error.message || 'An error occurred')
            })
            .finally(() => setIsProcessing(false))
        }
    }

    const clearForm = () => {
        setQuoteId(null)
        setArtistFee(0)
        setCar(0)
        setFood(0)
        setAccommodation(0)
        setVat(vatRateLow)
        setSelectedActId("")
        setMsg("")
    }

    const unRejectRequest = () => {
        // unarchive the request for this artist
        axios.post(`${process.env.REACT_APP_API}/requests/unarchive-request`, {
            artistId: currentUser.uid,
            requestId: request._id
        })
        .then((archiveResponse) => {
            toast.success(archiveResponse.data.message)
            setRequestRejected(false)

            if (request.artistId)
            {
                // if the unarchive succeeded and this is a direct request then inform the client
                axios.post(`${process.env.REACT_APP_API}/requests/inform-client-of-unrejected-request`, {
                    artistId: currentUser.uid,
                    selectedActId,
                    clientEmail: request.email,
                    requestId: request._id
                })
                .then((informClientResponse) => {
                    toast.success(informClientResponse.data.message)
                })
                .catch((informClientError) => {
                    toast.error(informClientError.data.error.message)
                })
            }
        })
        .catch((archiveError) => {
            toast.error(archiveError.data.error.message)
        })
    }

    const rejectRequest = () => {
        // archive the request for this artist with status rejected
        console.log("Reject request - archive")

        axios.post(`${process.env.REACT_APP_API}/requests/archive-request`, {
            artistId: currentUser.uid,
            requestId: request._id,
            archiveType: "Rejected"
        })
        .then((archiveResponse) => {
            toast.success(archiveResponse.data.message)
            console.log("and inform client")

            setRequestRejected(true)

            if (request.artistId)
            {
                // if the archive succeeded and this is a direct request then inform the client
                axios.post(`${process.env.REACT_APP_API}/requests/inform-client-of-rejected-request`, {
                    artistId: currentUser.uid,
                    artistEmail,
                    artistName,
                    selectedActId,
                    clientId: request.clientId,
                    clientEmail: request.email,
                    clientName: request.name,
                    requestId: request._id
                })
                .then((informClientResponse) => {
                    toast.success(informClientResponse.data.message)
                })
                .catch((informClientError) => {
                    toast.error(informClientError.data.error.message)
                })
            }
        })
        .catch((archiveError) => {
            toast.error(archiveError.data.error.message)
        })
    }

    const noQuoteMade = () => {
        return !request.quoted?.includes(currentUser.uid);
    }

    // there's some complexity here:
    //   - if a quote has been made then display amend
    //   - if a quote has not been made then display create
    //   - if a quote has been paid then state that it is no longer changeable
    //   - if a request has been paid or if it was only ever directed to a single artist
    //     then the artist Id should be present in the request. If another artist manages
    //     to land here then safeguard by providing a message that the request is covered
    // additionally, concerning rejection:
    //   - only an unquoted request can be rejected
    //   - rejected requests can be unrejected
    return (
        <div className='business-outlet' style={{display:'flex', flexDirection: 'row', justifyContent:'space-evenly', alignItems:'baseline', flexWrap:'wrap'}}>
            <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '500px', whiteSpace: 'normal' }}>
                <h2 style={{fontFamily: 'Mont'}}>Wedding Details</h2>
                <p className='request-header'>Client name - <span className='request-line-info'>{request && request.name}</span></p>
                <p className='request-header'>Date of wedding - <span className='request-line-info'>{moment(request.date).format('ddd, DD MMM YYYY')}</span></p>
                <p className='request-header'>Act requested - <span className='request-line-info'>{request && request.info.actType}</span></p>
                <p className='request-header'>Start time - <span className='request-line-info'>{request && request.info.startTime}</span></p>
                <p className='request-header'>End time - <span className='request-line-info'>{request && request.info.endTime}</span></p>
                <p className='request-header'>Venue - <span className='request-line-info'>{request && request.info.venue}</span></p>
                <p className='request-header'>Location - <span className='request-line-info'>{request && request.info.location}</span></p>
                <p className='request-header'>Notes -</p>
                <p className='request-line-info'>{request.notes}</p>

                {noQuoteMade() ? (
                    requestRejected ? (
                        <div>
                            <button
                                onClick={unRejectRequest}
                                className='primary-btn'
                            >
                                Unreject Request
                            </button>
                            <p style={{ marginTop: '5px', fontSize: '14px' }}>You previously rejected this request.</p>
                        </div>
                    ) : (
                        <div>
                            <button
                                onClick={rejectRequest}
                                className='primary-btn'
                            >
                                Reject Request
                            </button>
                            <p style={{ marginTop: '5px', marginBottom: '0px', fontSize: '14px' }}>
                            You may reject this request which will then
                            </p>
                            <p style={{ marginTop: '0px', marginBottom: '0px', fontSize: '14px' }}>
                                be removed from your list. If it was a direct
                            </p>
                            <p style={{ marginTop: '0px', fontSize: '14px' }}>
                                request the sender will be notified.
                            </p>
                        </div>
                    )
                ) : null}
            </div>

            <div style={{width: 500, textAlign:'center', maxWidth:'95vw'}}>
                {(request.artistId && currentUser.uid !== request.artistId) ? (
                    <div>
                        <h2>This request has been accepted by another artist</h2>
                    </div>
                ) : (
                    <div>
                        {!hasPaid ? (
                            <>
                                <h2 style={{fontFamily: 'Mont'}}>{!quoteId ? 'Create' : 'Amend'} Quote</h2>
                            </>
                        ) : (
                            <>
                                <h2 style={{color: 'green', fontWeight: 900, marginBottom: '0px'}}>
                                    Your quote for <em>{acceptedActName}</em> has been accepted and paid
                                </h2>
                                <p style={{ fontSize: '12px', marginTop: '0px', marginBottom: '20px' }}>
                                    (no further changes are now possible)
                                </p>
                            </>
                        )}
                        {acts.length > 0 && (
                            <>
                                <FormControl disabled={!!request.actName} fullWidth sx={{my:1}}>
                                    <InputLabel id="demo-simple-select-label">Acts</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selectedActId}
                                        label="Acts"
                                        onChange={(e) => handleActChange(e.target.value)}
                                    >
                                        {acts.map((act) => (
                                            <MenuItem key={act._id} value={act._id}>
                                                {act.actName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <p style={{ fontSize: '12px', marginTop: '0px', marginBottom: '20px' }}>
                                    Quotes can be created for each act but only one can be accepted.
                                </p>
                            </>
                        )}

                        <TextField sx={{m:1, width:'46%'}} disabled={hasPaid} label='Artist fee' size='small' InputProps={{startAdornment: <InputAdornment position="start">£</InputAdornment> }} value={artistFee} onChange={(e)=>setArtistFee(e.target.value)}/>
                        <TextField sx={{m:1, width:'46%'}} disabled={hasPaid} label='Mileage fee' size='small' InputProps={{startAdornment: <InputAdornment position="start">£</InputAdornment> }} value={car} onChange={(e)=>setCar(e.target.value)}/>
                        <TextField sx={{m:1, width:'46%'}} disabled={hasPaid} label='Food fee' size='small' InputProps={{startAdornment: <InputAdornment position="start">£</InputAdornment> }} value={food} onChange={(e)=>setFood(e.target.value)}/>
                        <TextField sx={{m:1, width:'46%'}} disabled={hasPaid} label='Accommodation fee' size='small' InputProps={{startAdornment: <InputAdornment position="start">£</InputAdornment> }} value={accommodation} onChange={(e)=>setAccommodation(e.target.value)}/>
                        <TextField sx={{m:1, width:'95%'}} size='small' label='Net total' value={total} disabled InputProps={{startAdornment: <InputAdornment position="start">£</InputAdornment> }}/>
                        <FormControl disabled={hasPaid} >
                            <FormLabel id="demo-row-radio-buttons-group-label">VAT</FormLabel>
                            <RadioGroup
                                value={vat}
                                onChange={(e) => setVat(e.target.value)}
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                            >
                                <FormControlLabel value={vatRateLow} control={<Radio />} label="0%" />
                                <FormControlLabel value={vatRateHigh} control={<Radio />} label="20%" />
                            </RadioGroup>
                        </FormControl>
                        <TextField sx={{my:1}} fullWidth  label='Gross total' value={gross} disabled InputProps={{startAdornment: <InputAdornment position="start">£</InputAdornment> }}/>
                        <p style={{fontSize: 12}}>Clients will not see the breakdown of costs.</p>
                        <p style={{ fontSize: 12 }}>
                            Our platform fee is passed on to the client ensuring the artist always receives what they deserve.
                            To avoid confusion, it is important that the above quoted artist costs are <b>not</b> included in any
                            message entered below. See{' '}
                            <a href="/artist-terms" target="_blank" rel="noopener noreferrer">
                                Artist Terms & Conditions
                            </a>.
                        </p>

                        <TextField
                            style={{  marginBottom: '20px' }}
                            multiline
                            rows={5}
                            fullWidth
                            sx={{my:1}}
                            disabled={hasPaid}
                            label='Message'
                            value={msg}
                            onChange={(e) => setMsg(e.target.value)}
                            placeholder={`Hello ${request.name} and congratulations! ${'\n'}Please find our music quote attached. Any questions let us know. ${'\n'}Best regards, ${'\n'}${artistName}`}

                        />
                        {!hasPaid  &&
                        <button
                            onClick={sendQuote}
                            className='primary-btn'
                            disabled={isProcessing || hasPaid || requestRejected}
                            >
                            {!quoteId ? 'Create Quote' : 'Update Quote'}
                        </button>
                        }
                    </div>)
                }
            </div>
        </div>
    )
}
