import React from 'react'
import './Success.css'
import disco from '../../Assets/success.webp'

export default function SuccessPage() {
  return (
    <div className='success-page'>

        <div style={{display:'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', alignItems: 'center', width: 1200, maxWidth: '100vw', margin: '2rem 0'}}>
            <div style={{ width: 600, maxWidth: '90vw'}}>
                <h1>Congratulations!</h1>
                <p>Firstly, we want to say a big congratulations on getting married and thank you for choosing First Dance to help find entertainment for your special day.</p>

                <p>Your request has been successfully received so you can sit back and wait for the quotes to come in!</p>
            </div>
            <img src={disco} id='disco-ball' alt='First Dance Music successfully booked wedding music'/>
        </div>


        <p style={{maxWidth: 1100, fontSize:16}}>By now you should have received a welcome email with your log in details. You can log in at any time to view your quotes and contact any of the musicians who have reached out by using our direct messaging tool.</p>

        <p style={{maxWidth: 1100, fontSize:16}}>If you experience any issues or have questions, please get in touch via our support tool or email <span style={{color: '#ecbdba'}}>info@firstdancemusic.co.uk</span></p>
    </div>
  )
}
